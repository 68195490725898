import React, { useEffect, useState } from 'react';
// import { FirestoreService } from "@core";
import { onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { StockInfor } from '../stockInfor/StockInfor';
import { Card } from '../card/Card';
import { FirestoreService } from 'core';

export const StockSymbol = () => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stockIndex, setStockIndex] = useState<any>();

  const initStock = async () => {
    let responseStocks = await FirestoreService.getListVNINDEX();
    let data: any = responseStocks?.docs.map((docSnapshot: any) =>
      docSnapshot.data()
    );

    if (data?.length > 0) {
      // VNINDEX
      let listVNINDEXCeiling = [];
      let listVNINDEXFloor = [];
      let listVNINDEXUp = [];
      let listVNINDEXDown = [];
      let listVNINDEXUnchanged = [];

      //VN30
      let listVN30Up = [];
      let listVN30Down = [];
      let listVN30Unchanged = [];
      let listVN30Ceiling = [];
      let listVN30Floor = [];

      //HNXINDEX
      let listHNXINDEXUp = [];
      let listHNXINDEXDown = [];
      let listHNXINDEXUnchanged = [];
      let listHNXINDEXCeiling = [];
      let listHNXINDEXFloor = [];

      //HNX30
      let listHNX30Up = [];
      let listHNX30Down = [];
      let listHNX30Unchanged = [];
      let listHNX30Ceiling = [];
      let listHNX30Floor = [];

      data?.forEach((item: any) => {
        if (item?.instrument_type_id === 'STOCK') {
          if (item?.last_price === item?.ceiling) {
            listVNINDEXCeiling.push(item);

            if (item?.vn30) {
              listVN30Ceiling.push(item);
            }

            if (item?.vn30) {
              listVN30Ceiling.push(item);
            }

            if (item?.market_id == 'HASTC') {
              listHNXINDEXCeiling.push(item);
            }

            if (item?.hnx30) {
              listHNX30Ceiling.push(item);
            }
          }
          if (item?.last_price === item?.floor) {
            listVNINDEXFloor.push(item);

            if (item?.vn30) {
              listVN30Floor.push(item);
            }

            if (item?.vn30) {
              listVN30Floor.push(item);
            }

            if (item?.market_id == 'HASTC') {
              listHNXINDEXFloor.push(item);
            }

            if (item?.hnx30) {
              listHNX30Floor.push(item);
            }
          }
          if (item?.ratio_change > 0) {
            listVNINDEXUp.push(item);

            if (item?.vn30) {
              listVN30Up.push(item);
            }

            if (item?.vn30) {
              listVN30Up.push(item);
            }

            if (item?.market_id == 'HASTC') {
              listHNXINDEXUp.push(item);
            }

            if (item?.hnx30) {
              listHNX30Up.push(item);
            }
          }
          if (item?.ratio_change < 0) {
            listVNINDEXDown.push(item);

            if (item?.vn30) {
              listVN30Down.push(item);
            }

            if (item?.vn30) {
              listVN30Down.push(item);
            }

            if (item?.market_id == 'HASTC') {
              listHNXINDEXDown.push(item);
            }

            if (item?.hnx30) {
              listHNX30Down.push(item);
            }
          }
          if (item?.ratio_change === 0) {
            listVNINDEXUnchanged.push(item);

            if (item?.vn30) {
              listVN30Unchanged.push(item);
            }

            if (item?.vn30) {
              listVN30Unchanged.push(item);
            }

            if (item?.market_id == 'HASTC') {
              listHNXINDEXUnchanged.push(item);
            }

            if (item?.hnx30) {
              listHNX30Unchanged.push(item);
            }
          }
        }
      });

      setStockIndex({
        VN30: {
          stockUp: listVN30Up.length,
          stockDown: listVN30Down.length,
          stockUnchanged: listVN30Unchanged.length,
          stockCeiling: listVN30Ceiling.length,
          stockFloor: listVN30Floor.length
        },
        VNINDEX: {
          stockUp: listVNINDEXUp.length,
          stockDown: listVNINDEXDown.length,
          stockUnchanged: listVNINDEXUnchanged.length,
          stockCeiling: listVNINDEXCeiling.length,
          stockFloor: listVNINDEXFloor.length
        },

        HNXINDEX: {
          stockUp: listHNXINDEXUp.length,
          stockDown: listHNXINDEXDown.length,
          stockUnchanged: listHNXINDEXUnchanged.length,
          stockCeiling: listHNXINDEXCeiling.length,
          stockFloor: listHNXINDEXFloor.length
        },
        HNX30: {
          stockUp: listHNX30Up.length,
          stockDown: listHNX30Down.length,
          stockUnchanged: listHNX30Unchanged.length,
          stockCeiling: listHNX30Ceiling.length,
          stockFloor: listHNX30Floor.length
        }
      });
    }
  };

  useEffect(() => {
    initStock();
    const intervalStockData = setInterval(() => {
      initStock();
    }, 15000);

    return () => clearInterval(intervalStockData);
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      FirestoreService.getStockExchange(),
      (querySnapshot: any) => {
        const data: any = querySnapshot?.docs.map((docSnapshot: any) =>
          docSnapshot.data()
        );
        if (data?.length > 0) {
          setStocks(
            data.filter((item: any) => item?.stock_code !== 'UPCOMINDEX')
          );
        }
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="flex space-x-2">
        {loading
          ? [1, 2, 3, 4].map(_ => {
              return (
                <Card
                  key={'loading_' + _}
                  className="w-1/4 cursor-pointer border border-[#F0F0F0] !p-4"
                >
                  <div className="w-full h-[75px] rounded animate-pulse bg-black/20"></div>
                  <div className="w-1/2 h-5 rounded-full animate-pulse bg-black/20 mt-3"></div>
                  <div className="w-[40px] h-4 rounded-full animate-pulse bg-black/20 mt-3"></div>
                </Card>
              );
            })
          : stocks
              ?.sort((a: any, b: any) => {
                return a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0;
              })
              .map((item: any) => {
                return (
                  <div
                    className="w-1/4"
                    key={item.stock_code}
                    // onClick={() => {
                    //   navigator(`/market/stock/${item.stock_code}/information`);
                    // }}
                  >
                    <StockInfor
                      {...item}
                      stockIndex={stockIndex?.[item?.stock_code]}
                    />
                  </div>
                );
              })}
      </div>
    </>
  );
};
