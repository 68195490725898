import { BuildingIcon, NoDataIcon, UserFillIcon } from 'assets/icons';
import { ComingSoonFeature } from 'components/ComingSoonFeature';
import { PieChart } from 'components/charts/PieChart';
import { SpinnerIcon } from 'components/spinner/SpinnerIcon';
import { MarketStockService } from 'core';
import { LeadershipType, StockRecordsType } from 'core/types/StockRecords.type';
import React, { useEffect, useState } from 'react';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';

const head_title_style =
  'px-4 py-2 mb-4 border-bottom-style text-base font-semibold leading-[22px]';

type DetailStockRecordsProps = {
  stockCode: string;
};

export const DetailStockRecords = ({ stockCode }: DetailStockRecordsProps) => {
  const [profileStock, setProfileStock] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const PieChartData = [
    {
      name: 'Tổ chức',
      y: parseFloat((profileStock?.institutionals_rate * 100).toFixed(2)),
      color: '#0B4A6F'
    },
    {
      name: 'Cá nhân',
      y: parseFloat((profileStock?.inviduals_rate * 100).toFixed(2)),
      color: '#36BFFA'
    },
    {
      name: 'Khác',
      y: parseFloat(
        (
          (1 -
            (profileStock?.institutionals_rate +
              profileStock?.inviduals_rate)) *
          100
        ).toFixed(2)
      ),
      color: '#E0F2FE'
    }
  ];

  const fetchProfileStock = async () => {
    setLoading(true);
    if (!stockCode) return;
    const params = {
      symbol: stockCode
    };
    const res = await MarketStockService.getProfileStock(params);
    if (res?.data?.status === 'Success') {
      setProfileStock(res?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProfileStock();
  }, []);

  return (
    <div className="flex gap-2">
      <div className="w-1/3 bg-black border-style">
        <div className={head_title_style}>
          <p>Thông tin công ty</p>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <SpinnerIcon className="scale-50" />
          </div>
        ) : profileStock ? (
          <div className="px-4 flex flex-col gap-4">
            <div className="flex gap-2 items-center">
              {/* <img src="" alt="" className="w-8 h-8 rounded-full bg-white" /> */}
              <p className="text-base font-semibold leading-[22px] block h-fit">
                {profileStock?.company_name}
              </p>
            </div>
            <p className="text-sm font-normal leading-5">
              {profileStock?.company_profile}
            </p>
          </div>
        ) : (
          <div className="flex flex-col gap-4 items-center justify-center h-full mt-[-39px]">
            <NoDataIcon />
            <p className="text-base font-normal leading-[22px] text-gray_dark">
              Chưa có dữ liệu
            </p>
          </div>
        )}
      </div>
      <div className="w-1/3 bg-black border-style">
        <div className={head_title_style}>
          <p>Thông tin về ngành</p>
        </div>
        {/* <div className="px-4 pb-4 flex gap-2">
          <div className="w-1/2 bg-black_blue rounded-md p-2 flex flex-col gap-4">
            <p className="text-sm font-semibold leading-5">Tài nguyên cơ bản</p>
            <div className="flex gap-1 p-2 bg-green_light text-vividMalachite w-fit rounded-[40px]">
              <RiArrowUpSFill className="text-xl" />
              <p className="text-sm font-normal leading-5">+0.25%</p>
            </div>
            <div className="text-xs font-semibold leading-[18px]">
              <div className="flex">
                <div className="flex flex-col items-center gap-1 w-[40%]">
                  <p className="text-high-price">5</p>
                  <div className="w-full h-1 bg-high-price rounded-l-xl diagonal-line-green"></div>
                </div>
                <div className="flex flex-col items-center gap-1 w-[20%]">
                  <p className="text-mustardYellow">200</p>
                  <div className=" w-full h-1 bg-mustardYellow diagonal-line-yellow"></div>
                </div>
                <div className="flex flex-col items-center gap-1 w-[40%]">
                  <p className="text-lowPriceColor">895</p>
                  <div className="w-full h-1 bg-lowPriceColor rounded-r-xl diagonal-line-red"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 bg-black_blue rounded-md p-2 flex flex-col gap-4">
            <p className="text-sm font-semibold leading-5">VN-Index</p>
            <div className="flex gap-1 p-2 bg-red_main text-carminePink w-fit rounded-[40px]">
              <RiArrowDownSFill className="text-xl" />
              <p className="text-sm font-normal leading-5">+0.25%</p>
            </div>
            <div className="text-xs font-semibold leading-[18px]">
              <div className="flex">
                <div className="flex flex-col items-center gap-1 w-[40%]">
                  <p className="text-high-price">5</p>
                  <div className="w-full h-1 bg-high-price rounded-l-xl diagonal-line-green"></div>
                </div>
                <div className="flex flex-col items-center gap-1 w-[20%]">
                  <p className="text-mustardYellow">200</p>
                  <div className=" w-full h-1 bg-mustardYellow diagonal-line-yellow"></div>
                </div>
                <div className="flex flex-col items-center gap-1 w-[40%]">
                  <p className="text-lowPriceColor">895</p>
                  <div className="w-full h-1 bg-lowPriceColor rounded-r-xl diagonal-line-red"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <ComingSoonFeature className="justify-center w-full pb-4" />
        <div className={`${head_title_style} border-t border-gray !mb-0`}>
          <p>Thông tin cổ đông</p>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <SpinnerIcon className="scale-50" />
          </div>
        ) : profileStock ? (
          <>
            <div className="flex items-center gap-[96px] py-2 px-[56px]">
              <PieChart data={PieChartData} width={200} />
              <div className="flex flex-col gap-2 text-xs font-normal leading-[18px]">
                {PieChartData.map((item, idx) => {
                  return (
                    <div className="flex items-center gap-2" key={idx}>
                      <div
                        className="w-[7px] h-[7px] rounded-full bg-blue-400"
                        style={{ backgroundColor: item.color }}
                        key={idx}
                      ></div>
                      <p>
                        {item.name} ({item.y}%)
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-4 px-4 pb-4 flex gap-2 text-sm font-semibold leading-5">
              <div className="bg-black_blue p-2 flex flex-col gap-4 w-1/2 rounded-md">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-full bg-darkCerulean flex justify-center items-center">
                    <BuildingIcon />
                  </div>
                  <p>Tổ chức</p>
                </div>
                <p>--</p>
                <div className="flex gap-1">
                  {/* <RiArrowDownSFill className="text-xl text-lowPriceColor" />
                  <p className="font-normal">
                    <span className="text-lowPriceColor">-1.08%</span> trong 1
                    tháng
                  </p> */}
                  --
                </div>
              </div>
              <div className="bg-black_blue p-2 flex flex-col gap-4 w-1/2 rounded-md">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-full bg-primary flex justify-center items-center">
                    <UserFillIcon />
                  </div>
                  <p>Cá nhân</p>
                </div>
                <p>--</p>
                {/* <p className="text-xs font-normal leading-[18px] tracking-[-0.6px] text-text_main_light">
                  Không thay đổi trong 1 tháng
                </p> */}
                --
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-4 items-center justify-center my-10">
            <NoDataIcon />
            <p className="text-base font-normal leading-[22px] text-gray_dark">
              Chưa có dữ liệu
            </p>
          </div>
        )}
      </div>
      <div className="w-1/3 bg-black border-style">
        <div className={head_title_style}>
          <p>Ban lãnh đạo</p>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <SpinnerIcon className="scale-50" />
          </div>
        ) : (
          <div
            className={`px-4 overflow-y-scroll max-h-[528px] hidden-scrollbar ${
              profileStock?.leaderships
                ? ' '
                : 'flex justify-center items-center h-full'
            }`}
          >
            {profileStock?.leaderships ? (
              profileStock?.leaderships?.map(
                (item: LeadershipType, index: number) => (
                  <div
                    className="flex flex-col gap-1 border-bottom-style last:border-0 py-2 last:pb-0 first:pt-0"
                    key={index}
                  >
                    <p className="text-sm font-semibold leading-5">
                      {item?.full_name}
                    </p>
                    <p className="text-xs font-normal leading-[18px] tracking-[-0.6px] text-text_main_light">
                      {item?.position_name}
                    </p>
                  </div>
                )
              )
            ) : (
              <div className="flex flex-col gap-4">
                <NoDataIcon />
                <p className="text-base font-normal leading-[22px] text-gray_dark text-center">
                  Chưa có dữ liệu
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
