import React from 'react';

export const LoadingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className="loading-spin"
    >
      <rect
        x="14.29"
        y="0.450195"
        width="2.83919"
        height="6.62477"
        rx="1.41959"
        fill="#241F21"
      />
      <rect
        x="22.5059"
        y="1.38086"
        width="2.66643"
        height="6.825"
        rx="1.33322"
        transform="rotate(30.1138 22.5059 1.38086)"
        fill="#E6E8EA"
      />
      <rect
        x="6.59766"
        y="2.78223"
        width="2.66517"
        height="6.825"
        rx="1.33259"
        transform="rotate(-31.3366 6.59766 2.78223)"
        fill="#414143"
      />
      <rect
        x="28.9121"
        y="6.51758"
        width="2.69193"
        height="6.825"
        rx="1.34597"
        transform="rotate(59.3961 28.9121 6.51758)"
        fill="#D2D4D6"
      />
      <rect
        x="1.40625"
        y="8.97266"
        width="2.75273"
        height="6.825"
        rx="1.37637"
        transform="rotate(-58.8154 1.40625 8.97266)"
        fill="#414143"
      />
      <rect
        x="31.3252"
        y="14.6465"
        width="2.83919"
        height="6.62477"
        rx="1.41959"
        transform="rotate(90 31.3252 14.6465)"
        fill="#BDBFC1"
      />
      <rect
        x="30.6641"
        y="22.7852"
        width="2.78352"
        height="6.825"
        rx="1.39176"
        transform="rotate(120 30.6641 22.7852)"
        fill="#A8AAAE"
      />
      <rect
        x="25.0479"
        y="29.0107"
        width="2.73651"
        height="6.825"
        rx="1.36825"
        transform="rotate(148.953 25.0479 29.0107)"
        fill="#95969A"
      />
      <rect
        x="17.1289"
        y="31.6816"
        width="2.83919"
        height="6.62477"
        rx="1.41959"
        transform="rotate(-180 17.1289 31.6816)"
        fill="#818286"
      />
      <rect
        x="8.93359"
        y="30.3613"
        width="2.69997"
        height="6.825"
        rx="1.34998"
        transform="rotate(-151.074 8.93359 30.3613)"
        fill="#6D6F73"
      />
      <rect
        x="3.06445"
        y="24.6699"
        width="2.7412"
        height="6.825"
        rx="1.3706"
        transform="rotate(-120.416 3.06445 24.6699)"
        fill="#585A5C"
      />
      <rect
        x="0.09375"
        y="17.5713"
        width="2.925"
        height="6.825"
        rx="1.4625"
        transform="rotate(-90 0.09375 17.5713)"
        fill="#585A5C"
      />
    </svg>
  );
};
