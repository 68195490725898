import React from 'react';
import Flaticon from 'assets/images/flaticon.png';
import { Button } from 'components';
import { useAccount } from 'core/contexts';
import { EKYC, STEP_EKYC } from 'core/constant';
import { createSearchParams, useNavigate } from 'react-router-dom';

type Props = {
  className?: string;
  isHideButton?: boolean;
  title?: any;
  imgClassName?: string;
};

export const NoAccount = ({ isHideButton, className, title, imgClassName }: Props) => {
  const { accountInfo, userInfo } = useAccount();
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (userInfo) {
      if (
        userInfo?.ekyc_status === EKYC.SUCCESS ||
        userInfo?.ekyc_status === EKYC.MANUAL
      ) {
        if (!accountInfo) {
          navigate({
            pathname: '/register',
            search: createSearchParams({
              step: STEP_EKYC.ADD_BANK
            }).toString()
          });
          return;
        }

        if (accountInfo) {
          if (!accountInfo?.pin) {
            navigate({
              pathname: '/register',
              search: createSearchParams({
                step: STEP_EKYC.CREATE_PIN
              }).toString()
            });
            return;
          }
          if (accountInfo?.pin && accountInfo?.sign_contract) {
            return;
          }
        }
      } else {
        navigate({
          pathname: '/register',
          search: createSearchParams({
            step: STEP_EKYC.ID_CHECK
          }).toString()
        });
      }
    }
  };

  return (
    <div className={`flex flex-col items-center text-center ${className}`}>
      <img
        src={Flaticon}
        className={`w-20 ${imgClassName}`}
        alt="Vui lòng hoàn thiện yêu cầu mở tài khoản"
      />
      <p className="text-sm font-normal leading-[22px] text-text_main_light mt-4 mb-8">
        {title || 'Vui lòng hoàn thiện yêu cầu mở tài khoản'}
      </p>
      {isHideButton ? null : (
        <Button
          variant="primary"
          className="lg:min-w-[311px]"
          onClick={handleNavigate}
        >
          Hoàn thiện ngay
        </Button>
      )}
    </div>
  );
};
