import React from 'react';

type WalletIconProps = {
  active?: boolean;
};

export const WalletIcon = ({ active }: WalletIconProps) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M22.5 6H15.5C13.9087 6 12.3826 6.63214 11.2574 7.75736C10.1321 8.88258 9.5 10.4087 9.5 12C9.5 13.5913 10.1321 15.1174 11.2574 16.2426C12.3826 17.3679 13.9087 18 15.5 18H22.5V20C22.5 20.2652 22.3946 20.5196 22.2071 20.7071C22.0196 20.8946 21.7652 21 21.5 21H3.5C3.23478 21 2.98043 20.8946 2.79289 20.7071C2.60536 20.5196 2.5 20.2652 2.5 20V4C2.5 3.73478 2.60536 3.48043 2.79289 3.29289C2.98043 3.10536 3.23478 3 3.5 3H21.5C21.7652 3 22.0196 3.10536 22.2071 3.29289C22.3946 3.48043 22.5 3.73478 22.5 4V6ZM15.5 8H23.5V16H15.5C14.4391 16 13.4217 15.5786 12.6716 14.8284C11.9214 14.0783 11.5 13.0609 11.5 12C11.5 10.9391 11.9214 9.92172 12.6716 9.17157C13.4217 8.42143 14.4391 8 15.5 8ZM15.5 11V13H18.5V11H15.5Z"
        fill="#0BA5EC"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M22.45 7V7.05H22.5H23.45V16.95H22.5H22.45V17V20C22.45 20.252 22.3499 20.4936 22.1718 20.6718C21.9936 20.8499 21.752 20.95 21.5 20.95H3.5C3.24804 20.95 3.00641 20.8499 2.82825 20.6718C2.65009 20.4936 2.55 20.252 2.55 20V4C2.55 3.74804 2.65009 3.50641 2.82825 3.32825C3.00641 3.15009 3.24804 3.05 3.5 3.05H21.5C21.752 3.05 21.9936 3.15009 22.1718 3.32825C22.3499 3.50641 22.45 3.74804 22.45 4V7ZM20.55 17V16.95H20.5H14.5C13.1872 16.95 11.9281 16.4285 10.9998 15.5002C10.0715 14.5719 9.55 13.3128 9.55 12C9.55 10.6872 10.0715 9.42813 10.9998 8.49982C11.9281 7.57152 13.1872 7.05 14.5 7.05H20.5H20.55V7V5V4.95H20.5H4.5H4.45V5V19V19.05H4.5H20.5H20.55V19V17ZM21.5 15.05H21.55V15V9V8.95H21.5H14.5C13.6911 8.95 12.9153 9.27134 12.3433 9.84332C11.7713 10.4153 11.45 11.1911 11.45 12C11.45 12.8089 11.7713 13.5847 12.3433 14.1567C12.9153 14.7287 13.6911 15.05 14.5 15.05H21.5ZM14.55 11.05H17.45V12.95H14.55V11.05Z"
        fill="#475467"
        stroke="#475467"
        strokeWidth="0.1"
      />
    </svg>
  );
};
