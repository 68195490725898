import React, { useState, ReactNode, useEffect } from 'react';
import {
  MARKET_STATUS,
  MarketStatusType,
  STOCK_MARKET_ID
} from 'core/constant';

interface ContextType {
  statusMarketHNX: MarketStatusType;
  statusMarketHOSE: MarketStatusType;
  statusMarketUPCOM: MarketStatusType;
  getStatuByMarket: (id: string) => MarketStatusType;
  getMarketName: (id?: string) => string;
}

type Props = {
  children: ReactNode;
};

const Context = React.createContext<ContextType>({} as ContextType);

export const MarketProvider: React.FC<Props> = ({ children }) => {
  const [statusMarketHNX, setStatusMarketHNX] = useState(MARKET_STATUS.CLOSE);
  const [statusMarketHOSE, setStatusMarketHOSE] = useState(MARKET_STATUS.CLOSE);
  const [statusMarketUPCOM, setStatusMarketUPCOM] = useState(
    MARKET_STATUS.CLOSE
  );

  const handleStatusHOSE = () => {
    const now = new Date();

    let startPendingTime = new Date();
    let endPendingTime = new Date();
    startPendingTime.setHours(11, 30, 0);
    endPendingTime.setHours(13, 0, 0);

    let startATOTime = new Date();
    let endATOTime = new Date();
    startATOTime.setHours(9, 0, 0);
    endATOTime.setHours(9, 15, 0);

    let startATCTime = new Date();
    let endATCTime = new Date();
    startATCTime.setHours(14, 30, 0);
    endATCTime.setHours(14, 45, 0);

    // check time and 0h - 9h and 14h45 - 0h: Close
    if (now >= endATCTime || now < startATOTime) {
      setStatusMarketHOSE(MARKET_STATUS.CLOSE);
      return;
    }

    // check time 9h - 9h15: ATO
    if (now >= startATOTime && now < endATOTime) {
      setStatusMarketHOSE(MARKET_STATUS.ATO);
      return;
    }

    // check time 11h30 - 13h: Pending
    if (now >= startPendingTime && now < endPendingTime) {
      setStatusMarketHOSE(MARKET_STATUS.PENDING);
      return;
    }

    // check time 14h30 - 14h45: ATC
    if (now >= startATCTime && now < endATCTime) {
      setStatusMarketHOSE(MARKET_STATUS.ATC);
      return;
    }

    setStatusMarketHOSE(MARKET_STATUS.OPEN);
  };

  const handleStatusHNX = () => {
    const now = new Date();

    let startPendingTime = new Date();
    let endPendingTime = new Date();
    startPendingTime.setHours(11, 30, 0);
    endPendingTime.setHours(13, 0, 0);

    let startOpenTime = new Date();
    startOpenTime.setHours(9, 0, 0);

    let startATCTime = new Date();
    let endATCTime = new Date();
    startATCTime.setHours(14, 30, 0);
    endATCTime.setHours(14, 45, 0);

    // check time and 0h - 9h and 14h45 - 0h: Close
    if (now >= endATCTime || now < startOpenTime) {
      setStatusMarketHNX(MARKET_STATUS.CLOSE);
      return;
    }

    // check time 11h30 - 13h: Pending
    if (now >= startPendingTime && now < endPendingTime) {
      setStatusMarketHNX(MARKET_STATUS.PENDING);
      return;
    }

    // check time 14h30 - 14h45: ATC
    if (now >= startATCTime && now < endATCTime) {
      setStatusMarketHNX(MARKET_STATUS.ATC);
      return;
    }
    setStatusMarketHNX(MARKET_STATUS.OPEN);
  };

  const handleStatusUPCOM = () => {
    const now = new Date();

    let startPendingTime = new Date();
    let endPendingTime = new Date();
    startPendingTime.setHours(11, 30, 0);
    endPendingTime.setHours(13, 0, 0);

    let startOpenTime = new Date();
    startOpenTime.setHours(9, 0, 0);
    let endOpenTime = new Date();
    endOpenTime.setHours(15, 0, 0);

    // check time and 0h - 9h and 15h - 0h: Close
    if (now >= endOpenTime || now < startOpenTime) {
      setStatusMarketUPCOM(MARKET_STATUS.CLOSE);
      return;
    }

    // check time 11h30 - 13h: Pending
    if (now >= startPendingTime && now < endPendingTime) {
      setStatusMarketUPCOM(MARKET_STATUS.PENDING);
      return;
    }

    setStatusMarketUPCOM(MARKET_STATUS.OPEN);
  };

  const handleStatus = () => {
    const now = new Date();
    const saturday = 6;
    const sunday = 0;

    // check time satuday - sunday: Đóng cửa -> HOSE, HNX
    if (now.getDay() === saturday || now.getDay() === sunday) {
      setStatusMarketHOSE(MARKET_STATUS.CLOSE);
      setStatusMarketHNX(MARKET_STATUS.CLOSE);
      setStatusMarketUPCOM(MARKET_STATUS.CLOSE);
      return;
    }

    handleStatusHOSE();
    handleStatusHNX();
    handleStatusUPCOM();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleStatus();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getStatuByMarket = (id: string): MarketStatusType => {
    if (id === STOCK_MARKET_ID.HOSE) {
      return statusMarketHOSE;
    }
    if (id === STOCK_MARKET_ID.HNX) {
      return statusMarketHNX;
    }
    if (id === STOCK_MARKET_ID.UPCOM) {
      return statusMarketUPCOM;
    }
    return MARKET_STATUS.CLOSE;
  };

  const getMarketName = (id?: string): string => {
    if (!id) {
      return '';
    }

    if (id === 'HASTC') {
      return 'HNX';
    }

    return id;
  };

  const value: ContextType = {
    statusMarketHNX,
    statusMarketHOSE,
    statusMarketUPCOM,
    getStatuByMarket,
    getMarketName
  };
  return <Context.Provider {...{ value, children }} />;
};

export function useMarket() {
  return React.useContext(Context);
}
