import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import { Button } from '../buttons/Button';
import { ACTION_ORDER, ACTION_ORDER_TYPE } from 'core/constant';
import { RiInformationLine } from 'react-icons/ri';
import { sha256 } from 'js-sha256';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { decryptData } from 'core/utils';

type ModalProps = {
  title: string;
  subTitle?: string;
  isOpen: boolean;
  loading?: boolean;
  closeModal: () => void;
  onSubmit: (pin: string) => void;
  stockInfo: {
    action: ACTION_ORDER_TYPE;
    symbol: string;
    price?: number | string;
    quanlity: number;
  };
  className?: string;
};

export const OrderModal = ({
  title,
  loading,
  subTitle,
  isOpen,
  onSubmit,
  closeModal,
  stockInfo
}: ModalProps) => {
  const [pinCode, setPinCode] = useState<string>();
  const [showPin, setShowPin] = useState(false);
  const pinCache = localStorage.getItem('PC') || undefined;
  const pin = decryptData(pinCache);

  useEffect(() => {
    if (!isOpen) {
      setPinCode(undefined);
      setShowPin(false);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      closeModal={closeModal}
      className={`max-w-[640px]`}
    >
      <div>
        <p className="text-sm mt-[-4px] mb-6">{subTitle}</p>
        <div className="text-[#98A2B3] text-sm rounded-md border border-[#D0D5DD] px-4 py-3">
          <p>
            Mã: <span className="font-semibold">{stockInfo.symbol}</span>
          </p>
          <p>
            Loại lệnh:{' '}
            <span
              className={`font-semibold ${
                stockInfo.action === ACTION_ORDER.B.value
                  ? 'text-[#00C802]'
                  : stockInfo.action === ACTION_ORDER.S.value
                  ? 'text-[#F04438]'
                  : ''
              }`}
            >
              {ACTION_ORDER[stockInfo.action]?.label}
            </span>
          </p>
          <p>
            Giá đặt:{' '}
            <span className="font-semibold">
              {typeof stockInfo.price === 'number'
                ? stockInfo.price.toLocaleString('en-US')
                : stockInfo.price}
            </span>
          </p>
          <p>
            KL đặt:{' '}
            <span className="font-semibold">
              {stockInfo.quanlity.toLocaleString('en-US')}
            </span>
          </p>
        </div>
        <div className="flex items-center gap-2 mt-4">
          <div className="checkbox">
            <input
              id="show-pin-checkbox"
              type="checkbox"
              className={'w-[20px] h-[20px] checkbox__input'}
              onChange={e => {
                if (e.target.checked) {
                  localStorage.setItem('IS_HIDE_PIN_POPUP', 'TRUE');
                } else {
                  localStorage.removeItem('IS_HIDE_PIN_POPUP');
                }
              }}
            />
            <label
              htmlFor="show-pin-checkbox"
              className={'text-sm checkbox__label'}
            >
              Không hiển thị xác nhận cho các lệnh tiếp theo
            </label>
          </div>

          <RiInformationLine className="text-[#667085] text-[18px]" />
        </div>
        <div className="flex items-center gap-4 mt-8">
          <div className="text-sm">Mã pin giao dịch</div>
          <div className="relative">
            <input
              value={pinCode}
              type={showPin ? 'text' : 'password'}
              disabled={!!pin}
              className={`w-[192px] !bg-[#0C111D] border border-[#D0D5DD] rounded-md text-sm pl-3 pr-10 py-2 placeholder:text-[#98A2B3]`}
              placeholder="Nhập mã PIN"
              defaultValue={pin ? '******' : ''}
              maxLength={6}
              onChange={e => {
                const v = e.target.value;
                setPinCode(v);
              }}
            />
            <button
              onClick={() => setShowPin(!showPin)}
              disabled={!!pin}
              type="button"
              className="absolute right-4 top-1/2 transform -translate-y-1/2 disabled:opacity-50"
            >
              {showPin ? <RiEyeLine /> : <RiEyeOffLine />}
            </button>
          </div>
          <div className="flex items-center gap-2">
            <div className="checkbox">
              <input
                id="save-pin-checkbox"
                type="checkbox"
                defaultChecked={!!pin}
                disabled={!!pin}
                className={'w-[20px] h-[20px] checkbox__input'}
                onChange={e => {
                  if (e.target.checked) {
                    localStorage.setItem('IS_SAVE_P', 'TRUE');
                  } else {
                    localStorage.removeItem('IS_SAVE_P');
                  }
                }}
              />
              <label
                htmlFor="save-pin-checkbox"
                className={'text-sm checkbox__label'}
              >
                Lưu mã PIN
              </label>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3 mt-10">
          <Button
            className="flex-1 text-primary font-semibold !bg-black"
            onClick={() => closeModal()}
            disabled={loading}
          >
            Đóng
          </Button>
          <Button
            variant="primary"
            className="flex-1"
            disabled={loading}
            onClick={() => {
              if (pin) {
                onSubmit(pin);
                return;
              }
              if (pinCode) {
                onSubmit(sha256(pinCode));
              }
            }}
          >
            Xác nhận
          </Button>
        </div>
      </div>
    </Modal>
  );
};
