import React, { useEffect, useState } from 'react';
import MarketMovements from './MarketMovements';
import MarketOverview from './MarketOverview';
import { AccountService } from 'core';
import { useAccount } from 'core/contexts/AccountContext';
export enum MARKET_TAB {
  OVERVIEW,
  MOVEMENTS
}

const MarketPage = () => {
  const { updateWatchlist } = useAccount();
  const [tabMarket, setTabMarket] = useState(MARKET_TAB[0]);

  const getFavoriteSymbol = async () => {
    const response = await AccountService.getwatchlist();
    if (response?.data) {
      const data = response?.data?.data;
      updateWatchlist(data);
    }
  };

  useEffect(() => {
    getFavoriteSymbol();
  }, []);

  return (
    <div className="min-h-full bg-black">
      <div className="flex gap-6 text-2xl font-semibold leading-[30px] border border-solid border-gray px-4 pt-2 text-cadetGrey">
        <div
          className={`${
            tabMarket === MARKET_TAB[0]
              ? 'border-b-4 border-solid border-primary'
              : ''
          } pb-[6px] cursor-pointer`}
          onClick={() => setTabMarket(MARKET_TAB[0])}
        >
          <p className={`${tabMarket === MARKET_TAB[0] ? 'text-[#fff]' : ''}`}>
            Tổng quan
          </p>
        </div>
        <div
          className={`${
            tabMarket === MARKET_TAB[1]
              ? 'border-b-4 border-solid border-primary'
              : ''
          } pb-[6px] cursor-pointer`}
          onClick={() => setTabMarket(MARKET_TAB[1])}
        >
          <p className={`${tabMarket === MARKET_TAB[1] ? 'text-[#fff]' : ''}`}>
            Diễn biến thị trường
          </p>
        </div>
      </div>
      {tabMarket === MARKET_TAB[0] ? <MarketOverview /> : <MarketMovements />}
    </div>
  );
};

export default MarketPage;
