import React, { useEffect, useState } from 'react';
import { FavoriteFillIcon, FavoriteIcon } from 'assets/icons';
import { AccountService } from 'core';
import { SpinnerIcon } from 'components';
import { useAccount } from 'core/contexts/AccountContext';
import { StartIcon } from 'assets/icons';

export const FavoriteSymbol = ({ symbol }: { symbol: string }) => {
  const { watchlist, updateWatchlist } = useAccount();
  const [loading, setLoading] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);

  const update = async () => {
    setLoading(true);
    let symbols = watchlist.map(i => i.symbol);

    if (isFavorited) {
      symbols.splice(symbols.indexOf(symbol), 1);
      setIsFavorited(false);
    } else {
      symbols.push(symbol);
      setIsFavorited(true);
    }

    const res = await AccountService.updateWatchlist(symbols);
    const data = res?.data?.data;
    if (data) {
      updateWatchlist(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (watchlist?.length <= 0 || !symbol) {
      return;
    }
    setIsFavorited(
      watchlist?.filter((item: any) => item?.symbol === symbol)?.length > 0
    );
  }, [watchlist, symbol]);

  return (
    <button
      className={`${isFavorited ? 'text-primary' : 'text-[#A4A4A4]'}`}
      onClick={() => {
        update();
      }}
    >
      {isFavorited ? <StartIcon /> : <StartIcon />}
    </button>
  );
};
