import { EKYC } from 'core/constant';
import { useAccount } from 'core/contexts';
import React, { useEffect } from 'react';
import { NoAccount } from './NoAccount';
import { AccountService } from 'core';
import { SpinnerIcon } from './spinner/SpinnerIcon';

type AccountNotificationProps = {
  className?: string;
  isHideButton?: boolean;
  imgClassName?: string;
};

export const AccountNotification = ({
  className,
  isHideButton = true,
  imgClassName
}: AccountNotificationProps) => {
  const { accountInfo, userInfo, updateUserInfo } = useAccount();
  const [loading, setLoading] = React.useState(true);
  const getUserData = async () => {
    setLoading(true);
    const res = await AccountService.getUserInfo();
    if (res?.data?.status === 'Success') {
      updateUserInfo(res?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  if (!loading) {
    if (userInfo?.ekyc_status === EKYC.SUCCESS) {
      return (
        <NoAccount
          title="SBBS đang xem xét yêu cầu của bạn. Chúng tôi sẽ gửi thông báo cho bạn khi hoàn tất."
          isHideButton
          className={className}
          imgClassName={imgClassName}
        />
      );
    } else {
      if (userInfo?.ekyc_status === EKYC.MANUAL) {
        if (accountInfo) {
          if (!accountInfo?.pin || !accountInfo?.sign_contract) {
            return (
              <NoAccount
                isHideButton={isHideButton}
                className={className}
                imgClassName={imgClassName}
              />
            );
          } else {
            return (
              <NoAccount
                title="SBBS đang xem xét yêu cầu của bạn. Chúng tôi sẽ gửi thông báo cho bạn khi hoàn tất."
                isHideButton
                className={className}
                imgClassName={imgClassName}
              />
            );
          }
        } else {
          return (
            <NoAccount
              isHideButton={isHideButton}
              className={className}
              imgClassName={imgClassName}
            />
          );
        }
      } else {
        return (
          <NoAccount
            isHideButton={isHideButton}
            className={className}
            imgClassName={imgClassName}
          />
        );
      }
    }
  } else {
    return (
      <div className="mt-[10px] flex justify-center">
        <SpinnerIcon className="scale-[0.5] w-20" />
      </div>
    );
  }
};
