export const METADATA_KEY = 'METADATA';
export const SYSTEMCONFIG_KEY = 'SYSTEMCONFIG';
export const LOGIN_KEY = 'LOGIN_KEY';
export const IS_VPBS_ENABLE_KEY = 'IS_VPBS_ENABLE_KEY';
export const USER_PROFILE_KEY = 'USER_PROFILE';
export const LAST_TIME_NEW_FEATURE_POPUP_KEY =
  'LAST_TIME_NEW_FEATURE_POPUP_KEY';
export const LAST_TIME_ACTIVE_POPUP_KEY = 'LAST_TIME_ACTIVE_POPUP_KEY';
export const LAST_TIME_EXPIRED_POPUP_KEY = 'LAST_TIME_EXPIRED_POPUP_KEY';
export const COUNT_EXPIRED_POPUP_KEY = 'COUNT_EXPIRED_POPUP_KEY';
export const F_TRANSFER_MAX_WITHDRAW_AMOUNT = 'F_TRANSFER_MAX_WITHDRAW_AMOUNT';
export const F_TRANSFER_MIN_WITHDRAW_AMOUNT = 'F_TRANSFER_MIN_WITHDRAW_AMOUNT';
