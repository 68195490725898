import React from 'react';
import OrderHistoryRow from './OrderHistoryRow';

type Props = {
  data?: any;
};

const OrderHistoryTable = ({ data }: Props) => {
  return (
    <>
      <table className="w-full order-book-table order-history-table">
        <thead>
          <tr className="!font-semibold">
            <th className={'font-semibold'}>
              <p>Mã</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                Ngày giờ
              </p>
            </th>
            <th className={'font-semibold w-[6%]'}>Lệnh</th>
            <th className={'font-semibold'}>
              <p>KL khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                KL đặt
              </p>
            </th>
            <th className={'font-semibold'}>
              <p>Giá khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                Giá đặt
              </p>
            </th>
            <th className={'font-semibold'}>Trạng thái</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            data?.map((i: any) => <OrderHistoryRow key={i.order_no} data={i} />)
          ) : (
            <OrderHistoryRow data={null} />
          )}
        </tbody>
      </table>
    </>
  );
};

export default OrderHistoryTable;
