import { SYSTEMCONFIG } from '../constant/api.constant';
import axiosInstance from './restful.service';
import { METADATA_KEY, SYSTEMCONFIG_KEY } from 'core/constant/storage.constant';

export type CONFIG_TYPE =
  | 'stockOrderSession'
  | 'metaData'
  | 'config'
  | 'vpbsConfig'
  | 'vpbsStockOrderSession';

export type ConfigItemType = {
  Key: string;
  Value: string;
};

export type StepPriceType = {
  stock_type: string;
  stock_exchange: string;
  from_price: number;
  price_step: number;
  to_price: number;
};

export type StockOrderSessionType = {
  index: number;
  order_session: number;
  stock_order_exchange: {
    code: string;
    stock_order: string[];
  };
  time_end: string;
  time_start: string;
};

export type ConfigType = {
  config: ConfigItemType[];
  metaData: ConfigItemType[];
  stepPrice: StepPriceType[];
  stockOrderSession: StockOrderSessionType[];
};

const objectify = (arr: any, mapKey: any, mapValue = (i: any) => i) =>
  arr.reduce((acc: any, item: any) => {
    acc[mapKey(item)] = mapValue(item);
    return acc;
  }, {});

const fetchMetaData = () => {
  const params = {
    configType: 'Metadata'
  };
  return axiosInstance.get(SYSTEMCONFIG.GET, { params });
};

const fetchSystemConfig = () => {
  const params = {
    configType: 'SystemConfig'
  };
  return axiosInstance.get(SYSTEMCONFIG.GET, { params });
};

const getMetaData = async () => {
  const localStore = localStorage?.getItem(METADATA_KEY);
  if (localStore) {
    return JSON.parse(localStore);
  } else {
    const res = await fetchMetaData();
    if (res?.data) {
      const data = res?.data?.data;
      const dataFormat = objectify(
        data,
        (p: any) => p.key,
        (p: any) => p.value
      );
      localStorage?.setItem(METADATA_KEY, JSON.stringify(dataFormat));
      return dataFormat;
    }
  }
};

const getSystemConfig = async () => {
  const localStore = localStorage?.getItem(SYSTEMCONFIG_KEY);
  if (localStore) {
    return JSON.parse(localStore);
  } else {
    const res = await fetchSystemConfig();
    if (res?.data) {
      const data = res?.data?.data;
      const dataFormat = objectify(
        data,
        (p: any) => p.key,
        (p: any) => p.value
      );
      localStorage?.setItem(SYSTEMCONFIG_KEY, JSON.stringify(dataFormat));
      return dataFormat;
    }
  }
};

export const ConfigService = {
  getMetaData,
  fetchSystemConfig,
  getSystemConfig
};
