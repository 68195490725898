import { Button } from 'components/buttons/Button';
import { Modal } from 'components/modal/Modal';
import { SpinnerIcon } from 'components/spinner/SpinnerIcon';
import { useAccount } from 'core/contexts';
import { BankService } from 'core/services/bank.service';
import { SelectItemType } from 'core/types';
import { BankListType } from 'core/types/BankUser.type';
import { removeAccents } from 'core/utils';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  RiCheckboxCircleFill,
  RiErrorWarningLine,
  RiSearchLine
} from 'react-icons/ri';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import { toast } from 'react-toastify';

type AddBankUserModalProps = {
  isShowModal: boolean;
  setIsShowModal: (isShow: boolean) => void;
  getBanksUser: () => void;
};

const AddBankUserModal = ({
  isShowModal,
  setIsShowModal,
  getBanksUser
}: AddBankUserModalProps) => {
  const [onSubmit, setOnSubmit] = useState<boolean>();
  const [banks, setBanks] = useState<SelectItemType[]>();
  const [bankSelected, setBankSelected] = useState<SelectItemType>();
  const [bankAccountInfo, setBankAccountInfo] = useState<any>();
  const { account } = useAccount();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onChange'
  });

  const getBankList = async () => {
    const res = await BankService.getListBank();

    if (res?.data?.status === 'Success') {
      const mapData = res?.data?.data?.map((bank: BankListType) => {
        return {
          value: bank?.name,
          label: bank?.descriptions,
          data: bank
        };
      });

      setBanks(mapData);
    }
  };

  const getBankAccountInfo = async (bankKey: string, accountNumber: string) => {
    const res = await BankService.getBankAccountInfo(bankKey, accountNumber);

    if (res?.data?.status === 'Success') {
      setBankAccountInfo(res?.data?.data);
      const accountName = res?.data?.data?.account_name
        ? removeAccents(res?.data?.data?.account_name)?.toLocaleUpperCase()
        : '';
      setValue('account_name', accountName);
    } else {
      console.log(res?.data?.error_message);
      setError('account_number', {
        type: 'custom',
        message:
          res?.data?.error_message || 'Lỗi kết nối mạng, vui lòng thử lại'
      });
    }
  };

  useEffect(() => {
    if (isShowModal) {
      reset();
      if (!banks) getBankList();
    }
  }, [isShowModal]);

  const handleCloseModal = () => {
    setIsShowModal(false);
    reset();
    setBankSelected(undefined);
  };

  const CaretDownIcon = () => {
    return <RiSearchLine size={24} color="#667085" />;
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const handleBankChange = (data: any) => {
    setBankSelected(data);
    setValue('bank_key', data?.data?.key, { shouldValidate: true });
    if (getValues('account_number') || getValues('account_name')) {
      setValue('account_number', '', { shouldValidate: false });
      setValue('account_name', '', { shouldValidate: false });
    }
    clearErrors('account_number');
    clearErrors('account_name');
  };

  const submitAddBankUserForm = async (formValues: any) => {
    setOnSubmit(true);
    try {
      const res = await BankService.addBankUser(formValues);

      if (res?.data?.status === 'Success') {
        toast('Thêm tài khoản ngân hàng thành công', {
          type: 'success',
          icon: <RiCheckboxCircleFill className="text-[#00C802]" />,
          autoClose: 2000
        });
        handleCloseModal();
        getBanksUser();
      } else {
        toast(
          res?.data?.error_message || 'Lỗi kết nối mạng, vui lòng thử lại',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />
          }
        );
      }
    } catch (error) {
      console.log(error);
      toast('Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />
      });
    }
    setOnSubmit(false);
  };

  const formatOptionLabel = ({ label, value }: any, { context }: any) => {
    if (context === 'menu') {
      return (
        <div>
          <div
            style={{
              color: 'white',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px'
            }}
          >
            {value}
          </div>
          <div
            style={{
              color: '#98A2B3',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '18px'
            }}
          >
            {label}
          </div>
        </div>
      );
    } else {
      return value;
    }
  };

  const debouncedSearchBankInfo = useCallback(
    debounce(async (accountNumber: string) => {
      await getBankAccountInfo(bankSelected?.data?.key, accountNumber);
    }, 0),
    [bankSelected]
  );

  const handleAccountNumberChange = (event: any) => {
    event.target.value = event.target.value.replace(/\s/g, '');
    if (!event?.target?.value) {
      setError('account_number', {
        type: 'required',
        message: 'Vui lòng nhập số tài khoản'
      });
      return;
    }
    setValue('account_number', event?.target?.value, { shouldValidate: true });
    if (event?.target?.value) {
      debouncedSearchBankInfo(event?.target?.value);
    }
  };

  return (
    <Modal
      isOpen={isShowModal}
      title="Thêm tài khoản ngân hàng"
      subtitle="Vui lòng nhập thông tin ngân hàng"
      subtitleClassName="!text-[14px] !font-normal leading-5"
      closeModal={() => {}}
      className={`max-w-[640px]`}
      typePopup="AddBankUser"
    >
      <div className="pt-3">
        <form
          onSubmit={handleSubmit(submitAddBankUserForm)}
          autoComplete="off"
          className=""
        >
          <div className="form-input">
            <label htmlFor="" className="form-input__label required-after">
              Ngân hàng
            </label>
            <Select
              className="react-select-popup"
              classNamePrefix="select"
              isSearchable={true}
              placeholder="Chọn ngân hàng"
              options={banks}
              onChange={handleBankChange}
              isMulti={false}
              components={{ DropdownIndicator }}
              formatOptionLabel={formatOptionLabel}
            />
            <input
              type="hidden"
              {...register('bank_key', {
                required: 'Vui lòng chọn ngân hàng'
              })}
            />
            <input type="hidden" {...register('branch_id', {})} />
            {errors?.bank_key && (
              <p className="form-errors mt-1">
                {errors.bank_key.message as string}
              </p>
            )}
          </div>
          <div className="form-input mt-2">
            <label htmlFor="" className="form-input__label required-after">
              Số tài khoản
            </label>
            <input
              className="form-input__input form-input__input_disabled"
              placeholder="Nhập số tài khoản"
              {...register('account_number', {
                required: 'Vui lòng nhập số tài khoản',
                onBlur: (event: any) => handleAccountNumberChange(event)
              })}
            />
            {errors?.account_number && (
              <p className="form-errors mt-1">
                {errors.account_number.message as string}
              </p>
            )}
          </div>
          <div className="form-input mt-2">
            <label htmlFor="" className="form-input__label required-after">
              Tên chủ tài khoản
            </label>
            <input
              className="form-input__input form-input__input_disabled"
              readOnly
              placeholder="Tên chủ tài khoản"
              {...register('account_name', {
                required: false,
                validate: value => {
                  if (
                    getValues('account_number') &&
                    value !==
                      removeAccents(
                        account?.local_name || ''
                      )?.toLocaleUpperCase()
                  ) {
                    return 'Tên chủ tài khoản ngân hàng không đúng với tên đăng ký, vui lòng kiểm tra lại số tài khoản';
                  }

                  return true;
                }
              })}
            />
            {errors?.account_name && (
              <p className="form-errors mt-1">
                {errors.account_name.message as string}
              </p>
            )}
          </div>
        </form>
      </div>
      <div className={`flex items-center gap-3 mt-16`}>
        <Button
          variant="secondary"
          className="flex-1"
          onClick={handleCloseModal}
        >
          Đóng
        </Button>
        <Button
          variant="primary"
          className="flex-1 flex gap-1 items-center"
          onClick={handleSubmit(submitAddBankUserForm)}
        >
          {onSubmit && (
            <SpinnerIcon className="scale-[0.2] w-3 h-3 mt-[-10px]" />
          )}
          Tiếp theo
        </Button>
      </div>
    </Modal>
  );
};

export default AddBankUserModal;
