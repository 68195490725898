import React, { useState, useEffect } from 'react';
import { OrderType } from 'core/types';
import { StockOrderRow, OrderModal } from 'components';
import { MarketStockService } from 'core';
import { toast } from 'react-toastify';
import { RiCheckboxCircleFill, RiErrorWarningLine } from 'react-icons/ri';
import { checkSavePin, isHidePopupConfirm } from 'core/utils';

export const StockOrderTable = ({
  data,
  onRefresh,
  onUpdateOrder
}: {
  data: OrderType[];
  onUpdateOrder: (order: OrderType) => void;
  onRefresh: () => void;
}) => {
  const [orderCancel, setOrderCancel] = useState<OrderType>();
  const [isShowCancelOrder, setIsShowCancelOrder] = useState(false);

  const onCancelOrder = async (pin: string, isCheckSavePinCode?: boolean) => {
    if (pin) {
      const paramsdata = {
        order_no: orderCancel?.order_no,
        pin: pin
      };
      const res = await MarketStockService.cancelOrder(paramsdata);
      const data = res?.data;

      if (data?.status === 'Fail') {
        toast(data?.error_message.split('|')[1], {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />
        });
        return;
      }
      if (data?.status === 'Success') {
        toast('Huỷ lệnh thành công', {
          type: 'success',
          icon: <RiCheckboxCircleFill className="text-[#00C802]" />
        });
        if (isShowCancelOrder) {
          setIsShowCancelOrder(false);
        }
        if (isCheckSavePinCode) {
          checkSavePin(pin);
        }
        setOrderCancel(undefined);
        onRefresh();
        return;
      }
    }
  };

  useEffect(() => {
    if (orderCancel) {
      const pinSaved = isHidePopupConfirm();
      if (pinSaved) {
        onCancelOrder(pinSaved);
        return;
      }
      setIsShowCancelOrder(true);
    } else {
      setIsShowCancelOrder(false);
    }
  }, [orderCancel]);

  return (
    <>
      <table className="w-full order-book-table">
        <thead>
          <tr className="!font-semibold align-top">
            <th className={'font-semibold'}>
              <p>Mã</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                Thời gian
              </p>
            </th>
            <th className={'font-semibold'}>Lệnh</th>
            <th className={'font-semibold'}>
              <p>KL khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                KL đặt
              </p>
            </th>
            <th className={'font-semibold'}>
              <p>Giá khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.6px]">
                Giá đặt
              </p>
            </th>
            <th className={'font-semibold'}>Tổng giá trị</th>
            <th className={'font-semibold text-right pr-[11px]'}>Trạng thái</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((i: any) => (
            <StockOrderRow
              key={i?.order_no}
              order={i}
              onUpdate={order => onUpdateOrder(order)}
              onCancel={order => setOrderCancel(order)}
            />
          ))}
        </tbody>
      </table>
      {orderCancel && (
        <OrderModal
          isOpen={isShowCancelOrder}
          title="Huỷ lệnh"
          subTitle={'Bạn có chắc chắn muốn huỷ lệnh này không?'}
          stockInfo={{
            action: orderCancel.bid_ask_type,
            symbol: orderCancel.stock_symbol,
            price: orderCancel.order_price,
            quanlity: orderCancel.order_quantity
          }}
          closeModal={() => setIsShowCancelOrder(false)}
          onSubmit={pin => onCancelOrder(pin, true)}
        />
      )}
    </>
  );
};
