import React from 'react';
import { Modal } from './Modal';
import { NoAccount } from 'components/NoAccount';
import { useAccount } from 'core/contexts';
import { EKYC } from 'core/constant';
import { AccountNotification } from 'components/AccountNotification';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  imgClassName?: string;
};

export const RequestCompleteAccountModal = ({
  isOpen,
  closeModal,
  imgClassName
}: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title={''}
      typePopup="none"
      closeModal={closeModal}
      hasCloseBtn
      className={`max-w-[640px] h-[362px] !pt-6`}
    >
      <AccountNotification
        isHideButton={false}
        className="mt-[9px]"
        imgClassName={imgClassName}
      />
    </Modal>
  );
};
