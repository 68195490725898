import React from 'react';

type HammerIconProps = {
  active?: boolean;
};

export const HammerIcon = ({ active }: HammerIconProps) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M14.5 20V22H2.5V20H14.5ZM15.086 0.686035L22.864 8.46404L21.45 9.88004L20.39 9.52604L17.913 12L23.57 17.657L22.156 19.071L16.5 13.414L14.096 15.818L14.379 16.95L12.964 18.364L5.186 10.586L6.601 9.17204L7.731 9.45404L14.025 3.16104L13.672 2.10104L15.086 0.686035Z"
        fill="#0BA5EC"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M17.8776 12.0354L23.4993 17.657L22.156 19.0003L16.5354 13.3787L16.5 13.3433L16.4646 13.3786L14.0606 15.7826L14.0406 15.8027L14.0475 15.8301L14.3236 16.9347L12.964 18.2933L5.25672 10.586L6.61634 9.22737L7.71889 9.50252L7.74634 9.50937L7.76635 9.48936L14.0604 3.19636L14.0822 3.17452L14.0724 3.14521L13.7292 2.1145L15.086 0.756728L22.7933 8.46403L21.4365 9.82278L20.4058 9.47858L20.3765 9.46879L20.3547 9.49063L17.8777 11.9646L17.8423 12L17.8776 12.0354ZM15.8284 4.18665L15.793 4.15129L15.7576 4.18665L8.68665 11.2566L8.65129 11.292L8.68664 11.3274L12.2216 14.8634L12.257 14.8987L12.2924 14.8634L19.3634 7.79336L19.3987 7.75801L19.3634 7.72265L15.8284 4.18665ZM14.45 20.05V21.95H2.55V20.05H14.45Z"
        fill="#475467"
        stroke="#475467"
        strokeWidth="0.1"
      />
    </svg>
  );
};
