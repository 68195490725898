import React from 'react';
import { Card } from '../card/Card';
import { TimeChart } from '../highcharts/TimeChart';
import { getColorHighLow } from 'core/utils';
import { ArrowStockIcon, SubtractIcon } from '../../assets/icons/index';
import { useMarket } from 'core/contexts';

type StockInforProps = {
  change: number;
  stock_name: string;
  price: number;
  ref_price: number;
  total_val: number;
  total_vol: number;
  chart_data: any;
  per_change: number;
  stock_code: string;
  stockIndex: any;
};

export const StockInfor = ({
  change,
  stock_name,
  price,
  ref_price,
  total_val,
  total_vol,
  chart_data,
  per_change,
  stock_code,
  stockIndex
}: StockInforProps) => {
  const { statusMarketHOSE, statusMarketHNX } = useMarket();

  return (
    <Card className="flex flex-col gap-2" value={per_change}>
      <div className="-ml-3">
        <TimeChart
          data={chart_data ? JSON.parse(chart_data) : [{ x: 0, y: 0 }]}
          change={per_change}
        />
      </div>
      <div className="flex flex-col gap-1 text-sm font-medium leading-5 text-[#FFFFFF]">
        <div className="flex justify-between">
          <div className="flex gap-1">
            <p>{stock_code}</p>
            <span className={`!text-${getColorHighLow(per_change)}`}>
              {price.toLocaleString('en-US')}
            </span>
          </div>
          <div className="flex gap-1">
            <p className={`!text-${getColorHighLow(price - ref_price)}`}>
              {price - ref_price > 0 ? '+' : ''}
              {(price - ref_price).toLocaleString('en-US')}
            </p>
            <p className={`!text-${getColorHighLow(per_change)}`}>{`(${
              per_change > 0 ? '+' : ''
            }${per_change}%)`}</p>
          </div>
        </div>
        <div className="flex justify-between">
          <p>{Math.floor(total_vol).toLocaleString('en-US')} CP</p>
          <p>{Math.floor(total_val / 1000000000).toLocaleString('en-US')} tỷ</p>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-[2px] justify-center items-center">
            <ArrowStockIcon color="#00C802" isIncrease={true} />
            <p className="text-high-price">
              {stockIndex?.stockUp} {` `}
              <span className="text-ceilingPriceColor">
                ({stockIndex?.stockCeiling})
              </span>
            </p>
          </div>
          <div className="flex gap-[2px] justify-center items-center">
            <SubtractIcon />
            <p className="text-mustardYellow">
              {stockIndex?.stockUnchanged} {` `}
            </p>
          </div>
          <div className="flex gap-[2px] justify-center items-center">
            <ArrowStockIcon color="#F04438" isIncrease={false} />
            <p className="text-lowPriceColor">
              {stockIndex?.stockDown} {` `}{' '}
              <span className="text-primary">({stockIndex?.stockFloor})</span>
            </p>
          </div>
          <p className="text-[#fff]">
            {stock_code === 'VN30' || stock_code === 'VNINDEX'
              ? statusMarketHOSE.label
              : stock_code === 'HNXINDEX' || stock_code === 'HNX30'
              ? statusMarketHNX.label
              : ''}
          </p>
        </div>
      </div>
    </Card>
  );
};
