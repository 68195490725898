import { PasswordVisibilityToggle } from 'components/PasswordVisibilityToggle';
import { Button } from 'components/buttons/Button';
import { Modal } from 'components/modal/Modal';
import { SpinnerIcon } from 'components/spinner/SpinnerIcon';
import React, { useState } from 'react';

type ConfirmPinModalProps = {
  isShowModal: boolean;
  setIsShowModal: (isShow: boolean) => void;
  handleSubmit: (data: any) => Promise<boolean>;
  successTitle: string;
  successSubTitle: string;
};

enum ModalStepEnum {
  Confirm,
  Success
}

const ConfirmPinModal = ({
  isShowModal,
  setIsShowModal,
  handleSubmit,
  successTitle,
  successSubTitle
}: ConfirmPinModalProps) => {
  const [modalStep, setModalStep] = useState<ModalStepEnum>(
    ModalStepEnum.Confirm
  );
  const [error, setError] = useState('');
  const [pin, setPin] = useState('');
  const [onSubmit, setOnSubmit] = useState<boolean>();
  const [isShowPin, setIsShowPin] = useState(false);

  const handleCloseModal = () => {
    setIsShowModal(false);
    setTimeout(() => {
      setModalStep(ModalStepEnum.Confirm);
      setError('');
      setPin('');
      setIsShowPin(false);
    }, 500);
  };

  const handleClickSubmitBtn = async () => {
    setOnSubmit(true);

    const result = await handleSubmit(pin);

    if (result) {
      setModalStep(ModalStepEnum.Success);
      setOnSubmit(false);
    } else {
      setOnSubmit(false);
    }
  };

  return (
    <Modal
      isOpen={isShowModal}
      title={`${
        modalStep === ModalStepEnum.Confirm ? 'Nhập mã PIN' : successTitle
      }`}
      classNameTitle={`${
        modalStep === ModalStepEnum.Success
          ? '!text-xl !font-semibold !leading-[26px]'
          : ''
      }`}
      subtitle={`
        ${
          modalStep === ModalStepEnum.Confirm
            ? 'Nhập mã PIN để tiếp tục'
            : successSubTitle
        }
        `}
      subtitleClassName={`!text-[14px] !font-normal leading-5 ${
        modalStep === ModalStepEnum.Success &&
        'text-text_main_light !text-base !leading-[22px]'
      }`}
      closeModal={() => {}}
      className={`max-w-[640px]`}
      typePopup={`${
        modalStep === ModalStepEnum.Success ? 'Success' : 'ConfirmPin'
      }`}
    >
      {modalStep === ModalStepEnum.Confirm && (
        <div className="pt-3">
          <div className="form-input relative">
            <label
              htmlFor=""
              className="text-sm font-normal leading-5 required-after"
            >
              Mã PIN hiện tại
            </label>
            <input
              type={isShowPin ? 'number' : 'password'}
              placeholder={'Nhập mã PIN hiện tại'}
              className={`form-input__input ${
                error && 'form-input__input_error'
              }`}
              onChange={e => {
                if (e.target.value.length <= 6) {
                  setPin(e.target.value);
                  setError('');
                }
              }}
              value={pin}
            ></input>
            <PasswordVisibilityToggle
              toggleVisibility={() => {
                setIsShowPin(!isShowPin);
              }}
              visible={isShowPin}
            />
            {error && (
              <div className="mt-[2px]">
                <p className="text-sm font-normal leading-5 text-carminePink">
                  {error}
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={`flex items-center gap-3 mt-8`}>
        {modalStep == ModalStepEnum.Success && (
          <Button
            className="flex-1 bg-primary font-semibold text-white"
            onClick={handleCloseModal}
          >
            Đóng
          </Button>
        )}

        {modalStep == ModalStepEnum.Confirm && (
          <>
            <Button
              variant="secondary"
              className="flex-1"
              onClick={handleCloseModal}
            >
              Đóng
            </Button>
            <Button
              variant="primary"
              className="flex-1 flex gap-1 items-center"
              onClick={handleClickSubmitBtn}
            >
              {onSubmit && (
                <SpinnerIcon className="scale-[0.2] w-3 h-3 mt-[-10px]" />
              )}
              Tiếp theo
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmPinModal;
