import moment from 'moment';
import React from 'react';

type TransactionHistoryTableProps = {
  data?: any;
};

const textStyle = 'text-sm font-semibold leading-5';

const TransactionHistoryTable = ({ data }: TransactionHistoryTableProps) => {
  return (
    <>
      <table className="w-full order-book-table transaction-history-table scrollable-table-body">
        <thead>
          <tr className="!font-semibold">
            <th className='!w-1/3'>
              <p>Loại lệnh</p>
            </th>
            <th>
              <div className="flex justify-end">
                <p>Ngày giao dịch</p>
              </div>
            </th>
            <th className='!w-[38%]'>
              <p>Số tiền</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.length === 0 && (
            <tr>
              <td className="text-left !w-1/3">
                <p className={textStyle}>-</p>
              </td>
              <td className="!text-right !px-0">
                <p className={textStyle}>-</p>
              </td>
              <td className="text-right !w-[38%] !px-0">
                <p className={textStyle}>-</p>
              </td>
            </tr>
          )}
          {data?.items?.map((item: any, index: number) => {
            return (
              <tr key={item?.order_no}>
                <td className="text-left !w-1/3">
                  <p className={textStyle}>
                    {item?.bid_ask_type === 'B' ? 'Mua ' : 'Bán '}
                    {item?.stock_symbol}
                  </p>
                </td>
                <td className="!text-right !px-0">
                  <p className={textStyle}>
                    {moment(item?.input_date).format('DD/MM/YYYY')}
                  </p>
                </td>
                <td className="text-right !w-[38%] !px-0">
                  <p className={textStyle}>
                    {(item?.filled_amount).toLocaleString('en-US')}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TransactionHistoryTable;
