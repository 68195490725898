import { ACCOUNT_PIN, BIZ, SYSTEMCONFIG, USER } from '../constant/api.constant';
import axiosInstance, {
  RestfulService,
  axiosInstanceIdentity
} from './restful.service';

const getAccountInfo = () => axiosInstance.get(BIZ.ACCOUNT);

const updateWatchlist = (symbols: string[]) => {
  return axiosInstance.put(BIZ.watchlist(), { symbols: symbols });
};

const getwatchlist = (params?: any) => {
  return axiosInstance.get(BIZ.watchlist(), { params });
};

const getTotalAsset = (params: any) => {
  return axiosInstance.get(BIZ.TOTAL_ASSET, { params });
};

const updatePin = (params: any) => {
  return axiosInstance.put(ACCOUNT_PIN.UPDATE, params);
};

const verifyPin = (params: any) => {
  return axiosInstance.post(ACCOUNT_PIN.VERIFY, params);
};

const checkPassword = (params: any) => {
  return axiosInstanceIdentity.post(USER.CHECK_PASSWORD, params);
};

const updatePassword = (params: any) => {
  return axiosInstanceIdentity.put(USER.UPDATE_PASSWORD, params);
};

const getUserInfo = () => {
  return axiosInstanceIdentity.get(USER.USER_INFO);
};

const getUrlUploadAvatar = (params: any) => {
  return axiosInstanceIdentity.get(USER.CLOUD_STORAGE, { params });
};

const updateAvatar = (params: any) => {
  return axiosInstanceIdentity.put(USER.UPDATE_AVATAR, params);
};

const checkEmailExist = (params: any) => {
  return axiosInstanceIdentity.get(USER.CHECK_EMAIL_EXIST, { params });
};

const sendOTP = (params: any) => {
  return axiosInstanceIdentity.post(USER.SEND_OTP, params);
};

const updateEmail = (params: any) => {
  return axiosInstanceIdentity.post(USER.UPDATE_EMAIL, params);
};

export const AccountService = {
  getAccountInfo,
  getwatchlist,
  updateWatchlist,
  getTotalAsset,
  updatePin,
  verifyPin,
  checkPassword,
  updatePassword,
  getUserInfo,
  getUrlUploadAvatar,
  updateAvatar,
  checkEmailExist,
  sendOTP,
  updateEmail
};
