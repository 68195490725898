import React, { useState, useEffect } from 'react';
import { useAccount } from 'core/contexts/AccountContext';
import { AccountNotification, StockPrice, TitleSection } from 'components';
import {
  STOCK_MARKET_TOP,
  STOCK_MARKET_PERIOD,
  STOCK_MARKET_TOP_LIST,
  EKYC
} from 'core/constant';
import StockItem from 'components/StockItem';
import {
  AccountService,
  MarketStockService,
  FirestoreService,
  ConfigService
} from 'core';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { onSnapshot } from 'firebase/firestore';
import moment from 'moment';
import { useMarket } from 'core/contexts';
import {
  Modal,
  StockOrderTable,
  StockOrder,
  StockOrderUpdate
} from 'components';
import { InfoTooltip, ComingSoonFeature, NoAccount } from 'components';
import { ACTION_ORDER, ACTION_ORDER_TYPE } from 'core/constant';
import { formatStockPrice } from 'core/utils';
import StockTable from 'components/StockTable';

const StockOrderPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigator = useNavigate();
  const symbolParam = searchParams.get('symbol');
  const actionParam = searchParams.get('action') as ACTION_ORDER_TYPE;
  const { account, userInfo, watchlist, updateWatchlist } = useAccount();
  const { getStatuByMarket, getMarketName } = useMarket();
  const [marketMovement, setMarketMovement] = useState<any>(
    STOCK_MARKET_TOP.TOP_INCREASE
  );
  const [marketMovementData, setMarketMovementData] = useState<any>([]);
  const [stockInfo, setStockInfo] = useState<any>();
  const [stockBuyingPower, setStockBuyingPower] = useState<any>();
  const [orderBook, setOrderBook] = useState<any>();
  const [stockData, setStockData] = useState<any>();
  const [showOrder, setShowOrder] = useState(true);
  const [orderUpdate, setOrderUpdate] = useState<any>();
  const [metadata, setMetadata] = useState<any>();

  const fetchStockInfo = async () => {
    if (symbolParam) {
      const response = await MarketStockService.getStockInfo(symbolParam);
      if (response?.data) {
        const data = response?.data?.data;
        setStockInfo(data);
      }
    }
  };

  const fetchOrderBook = async () => {
    if (symbolParam && account) {
      const fromDate = moment(new Date().setHours(0, 0, 0)).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = moment(new Date().setHours(23, 59, 59)).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      const paramsData = {
        accountNo: account?.account_id,
        pageSize: 100,
        fromDate: fromDate,
        toDate: toDate
      };
      const response = await MarketStockService.getOrderBook(paramsData);
      if (response?.data) {
        const data = response?.data?.data;
        setOrderBook(data);
      }
    }
  };

  const fetchStockBuyingPower = async () => {
    if (stockData && account) {
      const paramsStock = {
        accountNo: account?.account_id,
        stockSymbol: stockData?.symbol,
        exchange: stockData?.market_id,
        price: stockData?.last_price
      };
      const response = await MarketStockService.getStockBuyingPower(
        paramsStock
      );
      if (response?.data) {
        const data = response?.data?.data;
        setStockBuyingPower(data);
      }
    }
  };

  const getFavoriteSymbol = async () => {
    const response = await AccountService.getwatchlist();
    if (response?.data) {
      const data = response?.data?.data;
      updateWatchlist(data);
    }
  };

  const fetchMarketStock = async () => {
    const res = await MarketStockService.getMarketStock(
      marketMovement,
      STOCK_MARKET_PERIOD.ONE_DAY,
      5
    );
    const data = res?.data?.data;
    setMarketMovementData(data);
  };

  useEffect(() => {
    getFavoriteSymbol();
  }, []);

  useEffect(() => {
    const symbolCache = localStorage.getItem('SYMBOL') || 'AAA';

    if (symbolParam) {
      localStorage.setItem('SYMBOL', symbolParam);
    }

    if (!symbolParam && !actionParam) {
      searchParams.append('symbol', symbolCache);
      searchParams.append('action', ACTION_ORDER.B.value);
      setSearchParams(searchParams);

      return;
    }
    if (!actionParam) {
      searchParams.append('action', actionParam);
      setSearchParams(searchParams);
      return;
    }
    if (!symbolParam) {
      searchParams.append('symbol', symbolCache);
      setSearchParams(searchParams);
      return;
    }
  }, [symbolParam]);

  useEffect(() => {
    setStockBuyingPower(undefined);
    if (symbolParam) {
      const unsubscribe = onSnapshot(
        FirestoreService.getStockBySymbol(symbolParam),
        (querySnapshot: any) => {
          const data: any = querySnapshot?.docs.map((docSnapshot: any) =>
            docSnapshot.data()
          );
          if (data?.length > 0) {
            setStockData(data[0]);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
      setShowOrder(true);

      return () => {
        unsubscribe();
      };
    }
  }, [symbolParam]);

  useEffect(() => {
    fetchStockInfo();
  }, [symbolParam]);

  useEffect(() => {
    fetchOrderBook();
  }, [symbolParam, account]);

  useEffect(() => {
    fetchStockBuyingPower();
  }, [account]);

  useEffect(() => {
    if (!stockBuyingPower) {
      fetchStockBuyingPower();
    }
  }, [stockData, account]);

  useEffect(() => {
    fetchMarketStock();
  }, [marketMovement]);

  const formatNumber = (v: number) => {
    if (!v) {
      return '--';
    }
    if (v > 1000000000000) {
      return `${(v / 1000000000000).toLocaleString('en-US')}k tỷ`;
    }
    if (v > 1000000) {
      return `${(v / 1000000).toLocaleString('en-US')}tr`;
    }
    if (v > 10000) {
      return `${(v / 1000).toLocaleString('en-US')}k`;
    }
  };

  const getMetadata = async () => {
    const data = await ConfigService.getMetaData();
    setMetadata(data);
  };

  useEffect(() => {
    getMetadata();
  }, []);

  const getDescription = (key: string) => {
    return metadata?.[key];
  };

  return (
    <div className="flex h-full p-2 gap-2">
      <div className="w-[330px] h-full flex flex-col gap-2">
        <div className="flex flex-col gap-4 bg-black rounded-lg border-style !border-t-0">
          <TitleSection title="Cổ phiếu theo dõi" />
          <div className="h-[412px]">
            <StockTable
              data={watchlist}
              isRefreshWhenSort
              handleClickStock={(stockCode: string) => {
                searchParams.append('stock', stockCode);
                setSearchParams(searchParams);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 bg-black rounded-lg border-style !border-t-0">
          <TitleSection title="Diễn biến thị trường" />
          <div className="px-4">
            <div className="py-1 overflow-auto scrollbar-horizontal">
              <div className="flex gap-x-2 flex-nowrap">
                {STOCK_MARKET_TOP_LIST.map(i => (
                  <StockItem
                    key={i.value}
                    className="!text-xs"
                    isActived={marketMovement === i.value}
                    handleClickStock={() => {
                      setMarketMovement(i.value);
                    }}
                  >
                    {i.label}
                  </StockItem>
                ))}
              </div>
            </div>
          </div>
          <div className="h-[511px]">
            <StockTable
              data={marketMovementData?.items}
              isRefreshWhenSort
              handleClickStock={(stockCode: string) => {
                searchParams.append('stock', stockCode);
                setSearchParams(searchParams);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 text-base leading-[22px] flex flex-col gap-2">
        <div className="bg-black rounded-lg border-style !border-t-0">
          <TitleSection title="Biểu đồ" />
          <div className="px-4 py-6 flex">
            <div className="w-1/4 border-r border-[#475467] pr-3">
              <div className="text-[36px] leading-[36px] font-semibold">
                {symbolParam}
              </div>
              <div className="flex items-center text-sm">
                <span className="whitespace-nowrap pr-1">
                  {getMarketName(stockData?.market_id)} -{' '}
                  {getStatuByMarket(stockData?.market_id).label}
                </span>
                <span
                  className="w-3 h-3 block rounded-full"
                  style={{
                    backgroundColor: getStatuByMarket(stockData?.market_id)
                      .color
                  }}
                ></span>
              </div>
            </div>
            <div className="w-1/4 pl-6 text-sm">
              <div className="flex justify-between">
                <div>GTGD</div>

                <div>{formatNumber(stockData?.total_val)}</div>
              </div>
              <div className="flex justify-between">
                <div>KLGD</div>
                <div>
                  {stockData?.total_vol
                    ? (stockData?.total_vol).toLocaleString('en-US')
                    : '--'}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-[2px]">
                  <span>Tỉ lệ ký quỹ</span>
                  <InfoTooltip
                    title={getDescription('MTA_ORDER_TOOLTIP_MARGIN_RATE')}
                  />
                </div>
                <div>{(stockBuyingPower?.margin_ratio || 0) * 100}%</div>
              </div>
            </div>
            <div className="w-1/4 pl-6 text-sm">
              <div className="flex justify-between">
                <div>TC</div>
                <div className="text-mustardYellow">
                  {formatStockPrice(stockData?.ref_price)}
                </div>
              </div>
              <div className="flex justify-between">
                <div>Trần</div>
                <div className="text-ceilingPriceColor">
                  {formatStockPrice(stockData?.ceiling)}
                </div>
              </div>
              <div className="flex justify-between">
                <div>Sàn</div>
                <div className="text-floorPriceColor">
                  {formatStockPrice(stockData?.floor)}
                </div>
              </div>
            </div>
            <div className="w-1/4 pl-6 text-sm">
              <div className="flex justify-between">
                <div>TB</div>
                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.close || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="!p-0 !m-0"
                />
              </div>
              <div className="flex justify-between">
                <div>Cao</div>
                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.high || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="!p-0 !m-0"
                />
              </div>
              <div className="flex justify-between">
                <div>Thấp</div>

                <StockPrice
                  ceiling={stockData?.ceiling || 0}
                  floor={stockData?.floor || 0}
                  last_price={stockData?.low || 0}
                  ref_price={stockData?.ref_price || 0}
                  className="!p-0 !m-0"
                />
              </div>
            </div>
          </div>
          {/* <div className="px-4 py-6 text-center">
            <ComingSoonFeature className="mt-12" />
          </div> */}
        </div>
        <div className="flex-1 bg-black rounded-lg border-style !border-t-0">
          <TitleSection title="Sổ lệnh" />
          <div className="pt-2 px-4 pb-4">
            {account ? (
              <div>
                <div className="px-4 py-2 bg-black_blue text-sm font-normal leading-5 rounded-sm mb-3 lg:flex lg:justify-between">
                  <p>Giá trị khớp lệnh</p>
                  <div className="flex gap-10">
                    <div className="flex gap-1">
                      <p>Mua:</p>
                      <span
                        className={`font-semibold ${
                          orderBook?.total_filled_buy_amount > 0
                            ? 'text-high-price'
                            : ''
                        }`}
                      >
                        {(
                          orderBook?.total_filled_buy_amount || 0
                        ).toLocaleString('en-US')}
                        đ
                      </span>
                    </div>
                    <div className="flex gap-1">
                      <p>Bán:</p>
                      <span
                        className={`font-semibold ${
                          orderBook?.total_filled_sell_amount > 0
                            ? 'text-lowPriceColor'
                            : ''
                        }`}
                      >
                        {orderBook?.total_filled_sell_amount > 0 ? '+' : ''}
                        {(
                          orderBook?.total_filled_sell_amount || 0
                        ).toLocaleString('en-US')}
                        đ
                      </span>
                    </div>
                  </div>
                </div>
                <StockOrderTable
                  data={orderBook?.items}
                  onUpdateOrder={order => {
                    navigator(
                      `/stock-order?symbol=${order.stock_symbol}&action=${ACTION_ORDER.U.value}`
                    );
                    setOrderUpdate(order);
                  }}
                  onRefresh={() => {
                    fetchOrderBook();
                  }}
                />
              </div>
            ) : (
              <AccountNotification className="mt-20" isHideButton={false} />
            )}
          </div>
        </div>
      </div>
      {showOrder && actionParam !== ACTION_ORDER.U.value ? (
        <StockOrder
          type={actionParam}
          stockBuyingPower={stockBuyingPower}
          stockData={stockData}
          stockInfo={stockInfo}
          onClose={() => setShowOrder(false)}
          onSuccess={() => {
            fetchOrderBook();
            fetchStockBuyingPower();
          }}
        />
      ) : null}
      {!!orderUpdate ? (
        <StockOrderUpdate
          stockBuyingPower={stockBuyingPower}
          order={orderUpdate}
          stockData={stockData}
          stockInfo={stockInfo}
          onClose={() => {
            navigator(
              `/stock-order?symbol=${symbolParam}&action=${ACTION_ORDER.B.value}`
            );
            setOrderUpdate(undefined);
          }}
          onSuccess={() => {
            navigator(
              `/stock-order?symbol=${symbolParam}&action=${ACTION_ORDER.B.value}`
            );
            setOrderUpdate(undefined);
            fetchOrderBook();
            fetchStockBuyingPower();
          }}
        />
      ) : null}
    </div>
  );
};

export default StockOrderPage;
