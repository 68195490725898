import React from 'react';
import { ArrowRight } from 'assets/icons';

type Props = {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  handleClickSettingItem?: () => void;
};

export const SettingItem = ({
  className,
  children,
  title,
  handleClickSettingItem
}: Props) => {
  return (
    <div
      className="flex p-4 justify-between border-b border-solid border-tab_background cursor-pointer"
      onClick={handleClickSettingItem}
    >
      <div className="flex gap-4">
        {children}
        <p className="text-sm font-semibold leading-5">{title}</p>
      </div>
      <ArrowRight />
    </div>
  );
};
