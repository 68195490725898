import React from 'react';
import { Table } from 'antd';
import { AssetStockItem } from 'core/types';
import { formatCurrency } from 'core/utils';
import { FavoriteSymbol } from 'components';
import { Link } from 'react-router-dom';

const { Column, ColumnGroup } = Table;
const AssetsTable = ({ data }: { data: AssetStockItem[] }) => (
  <div className="overflow-x-auto scrollbar-horizontal">
    <Table
      dataSource={data}
      className="asset_table min-w-[1200px]"
      pagination={false}
      showSorterTooltip={false}
      // scroll={{ x: 500 }}
    >
      <Column title="&nbsp;" dataIndex="start" key="start" className="w-4" />
      <Column
        title="Mã CK"
        dataIndex="stock_symbol"
        key="stock_symbol"
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.stock_symbol.localeCompare(b.stock_symbol)
        }
        width={'5%'}
        render={(value, record) => (
          <div className="flex items-center gap-2">
            <FavoriteSymbol symbol={value} />
            {value}
          </div>
        )}
      />

      <Column
        title="Tổng"
        dataIndex="total_quantity"
        key="total_quantity"
        align="right"
        width={'5%'}
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.total_quantity - b.total_quantity
        }
        render={(value, record) => <>{formatCurrency(record.total_quantity)}</>}
      />

      <Column
        title="Khả dụng"
        dataIndex="available_quantity"
        key="available_quantity"
        align="right"
        width={'7%'}
        render={(value, record: AssetStockItem) => (
          <>{formatCurrency(record.available_quantity)}</>
        )}
      />

      <ColumnGroup title="Mua chờ về">
        <Column
          title="T0"
          dataIndex="t0_quantity"
          key="t0_quantity"
          align="center"
          width={'6%'}
          render={value => <>{value ? formatCurrency(value) : '-'}</>}
        />
        <Column
          title="T1"
          dataIndex="t1_quantity"
          key="t1_quantity"
          width={'6%'}
          render={value => <>{value ? formatCurrency(value) : '-'}</>}
        />
        <Column
          title="T2"
          dataIndex="t2_quantity"
          key="t2_quantity"
          width={'6%'}
          render={value => <>{value ? formatCurrency(value) : '-'}</>}
        />
      </ColumnGroup>
      <Column
        title="CP chờ bán"
        dataIndex="wait_for_sell_quantity"
        key="wait_for_sell_quantity"
        align="right"
        width={'5%'}
        render={value => <>{formatCurrency(value)}</>}
      />
      <Column
        title="Giá vốn"
        dataIndex="average_price"
        key="average_price"
        align="right"
        width={'6.5%'}
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.average_price - b.average_price
        }
        render={value => <>{formatCurrency(value)}</>}
      />

      <Column
        title="Giá TT"
        dataIndex="market_price"
        key="market_price"
        align="right"
        width={'6.5%'}
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.market_price - b.market_price
        }
        render={value => <>{formatCurrency(value)}</>}
      />
      <Column
        title="Vốn"
        dataIndex="capital_price"
        key="capital_price"
        align="right"
        width={'8%'}
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.average_price * a.total_quantity -
          b.average_price * b.total_quantity
        }
        render={(_, record) => (
          <>{formatCurrency(record.average_price * record.total_quantity)}</>
        )}
      />
      <Column
        title="Giá trị TT"
        dataIndex="market_value"
        key="market_value"
        align="right"
        width={'7%'}
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.market_price * a.total_quantity - b.market_price * b.total_quantity
        }
        render={(_, record) => (
          <>{formatCurrency(record.market_price * record.total_quantity)}</>
        )}
      />
      <Column
        title="Lãi/lỗ"
        dataIndex="change_amount"
        key="change_amount"
        align="right"
        width={'7%'}
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.change_amount - b.change_amount
        }
        render={value => (
          <span
            className={`${
              value > 0 ? 'text-high-price' : 'text-lowPriceColor'
            }`}
          >
            {value > 0 ? '+' : ''}
            {formatCurrency(value)}
          </span>
        )}
      />
      <Column
        title="Lãi/lỗ(%)"
        dataIndex="change_percent"
        key="achange_percentge"
        align="right"
        width={'7%'}
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.change_percent - b.change_percent
        }
        render={value => (
          <span
            className={`${
              value > 0 ? 'text-high-price' : 'text-lowPriceColor'
            }`}
          >
            {value > 0 ? '+' : ''}
            {value.toFixed(2) + '%'}
          </span>
        )}
      />
      <Column
        title="Quyền"
        dataIndex="mortgage_quantity"
        width={'7%'}
        key="mortgage_quantity"
        align="right"
        sorter={(a: AssetStockItem, b: AssetStockItem) =>
          a.mortgage_quantity - b.mortgage_quantity
        }
        render={value => <>{value ? formatCurrency(value) : '-'}</>}
      />
      <Column
        title="Hành động"
        dataIndex="stock_symbol"
        key="stock_symbol"
        align="right"
        render={value => (
          <div className="flex justify-end">
            <Link
              to={`/stock-order?symbol=${value}&action=S`}
              className="w-[50px] h-[26px] text-xs font-bold leading-[18px] rounded bg-carminePink flex items-center justify-center hover:text-white"
            >
              Bán
            </Link>
          </div>
        )}
      />
      <Column title="&nbsp;" dataIndex="start" key="end" className="w-4" />
    </Table>
  </div>
);

export default AssetsTable;
