import React, { useState, useEffect } from 'react';
import { useAccount } from 'core/contexts/AccountContext';
import { AccountNotification, TitleSection } from 'components';
import { AssetsService } from 'core/services';
import { PieChart } from 'components';
import { formatCurrency } from 'core/utils';
import {
  RiAddBoxFill,
  RiExchangeBoxFill,
  RiCheckboxIndeterminateFill,
  RiHandCoinFill,
} from 'react-icons/ri';

import CashHistories from './components/CashHistories';
import { Link } from 'react-router-dom';
import { AccountCashDistributionType } from 'core/types/Money.type';
import { PieChartItem } from 'core/types';
import { NoDataIcon } from 'assets/icons';

const AssetsPage = () => {
  const { account, userInfo } = useAccount();
  const [cashAsset, setcashAsset] = useState<any>();
  const defaultBgColors = [
    '#00629B',
    '#0086C9',
    '#0BA5EC',
    '#7CD4FD',
    '#E0F2FE'
  ];
  const [accountCashDistribution, setAccountCashDistribution] =
    useState<AccountCashDistributionType[]>();
  const [pieChartData, setPieChartData] = useState<PieChartItem[]>([]);

  const getAccountCashDistribution = async (accountNo: any) => {
    const res = await AssetsService.getAccountCashDistribution(accountNo);
    if (res?.data?.status === 'Success') {
      setAccountCashDistribution(res?.data?.data);
    }
  };

  const fetchcashAsset = async () => {
    if (account) {
      const res = await AssetsService.getCashPortfolio(account.account_id);
      const data = res?.data?.data;
      setcashAsset(data);
    }
  };

  useEffect(() => {
    fetchcashAsset();
    if (account?.account_id) {
      getAccountCashDistribution(account?.account_id);
    }
  }, [account]);

  useEffect(() => {
    if (accountCashDistribution) {
      const data = accountCashDistribution?.map((item: any, index: number) => {
        return {
          y: item?.percent,
          name: '',
          color: defaultBgColors[index]
        };
      });
      setPieChartData(data);
    }
  }, [accountCashDistribution]);

  return (
    <div className="flex min-h-full gap-2 p-2">
      <div className="flex-1 flex flex-col gap-2">
        <div className="flex justify-between px-[30px] py-5 bg-black rounded-lg border-style">
          <Link to="/deposit" className="p-4 flex flex-col items-center gap-2">
            <RiAddBoxFill className="text-primary text-[56px]" />
            <span className="text-sm font-semibole">Nạp tiền</span>
          </Link>
          <Link
            to="/transfer-cash"
            className="p-4 flex flex-col items-center gap-2"
          >
            <RiExchangeBoxFill className="text-primary text-[56px]" />
            <span className="text-sm font-semibole">Chuyển tiền</span>
          </Link>
          <Link to="/withdraw" className="p-4 flex flex-col items-center gap-2">
            <RiCheckboxIndeterminateFill className="text-primary text-[56px]" />
            <span className="text-sm font-semibole">Rút tiền</span>
          </Link>
          <Link
            to="/advance-cash"
            className="p-4 flex flex-col items-center gap-2"
          >
            <RiHandCoinFill className="text-primary text-[56px]" />
            <span className="text-sm font-semibole">Ứng tiền</span>
          </Link>
        </div>
        <div className='bg-black rounded-lg border-style !border-t-0'>
          <TitleSection title="Tổng quan tiền" />
          <div
            className={`py-4 px-6 ${
              !account ? 'flex items-center justify-center' : ''
            }`}
          >
            {account ? (
              (accountCashDistribution?.length as any) > 0 ? (
                <div className="pr-5 md:flex md:items-center xl:gap-10 xl:pl-[50px] xl:pr-[70px]">
                  <PieChart data={pieChartData} />
                  <div className="flex-1">
                    <ul className="flex flex-col gap-4">
                      {accountCashDistribution?.map((i: any, idx) => (
                        <li
                          key={`chart-desc-${idx}`}
                          className="flex justify-between items-center"
                        >
                          <div className="flex items-center gap-2">
                            <div
                              className="w-2 h-2"
                              style={{ backgroundColor: defaultBgColors[idx] }}
                            ></div>
                            <span className="text-sm">{`${i.name} (${i?.percent}%)`}</span>
                          </div>
                          <div className="text-sm">
                            {formatCurrency(i.amount)}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-4 items-center justify-center my-10">
                  <NoDataIcon />
                  <p className="text-base font-normal leading-[22px] text-gray_dark">
                    Chưa có dữ liệu
                  </p>
                </div>
              )
            ) : (
              <AccountNotification />
            )}
          </div>
        </div>
        <div className="pb-[129px] bg-black rounded-lg border-style !border-t-0">
          <TitleSection title="Quản lý tiền" />
          <div
            className={`p-4 ${
              !account ? 'flex items-center justify-center' : ''
            }`}
          >
            {account ? (
              <>
                <ul>
                  <li className="flex justify-between border-b border-gray py-3">
                    <span className="text-sm">Tiền có thể rút</span>
                    <span className="text-base font-semibold">
                      {formatCurrency(cashAsset?.withdraw_cash_bal)}
                    </span>
                  </li>
                  <li className="flex justify-between border-b border-gray py-3">
                    <span className="text-sm">Tiền thực hiện quyền mua</span>
                    <span className="text-base font-semibold">
                      {formatCurrency(cashAsset?.subscription_amount)}
                    </span>
                  </li>
                  <li className="flex justify-between border-b border-gray py-3">
                    <span className="text-sm">Tiền mua chờ khớp</span>
                    <span className="text-base font-semibold">
                      {formatCurrency(cashAsset?.buy_consideration)}
                    </span>
                  </li>
                  <li className="flex justify-between border-b border-gray py-3">
                    <span className="text-sm">Tiền bán chờ về</span>
                    <span className="text-base font-semibold">
                      {formatCurrency(cashAsset?.under_due_receive)}
                    </span>
                  </li>
                  <li className="flex justify-between border-b border-gray py-3">
                    <span className="text-sm">Lãi tiền gửi đã nhận</span>
                    <span className="text-base font-semibold">
                      {formatCurrency(cashAsset?.bought_consideration)}
                    </span>
                  </li>
                  <li className="flex justify-between border-b border-gray py-3">
                    <span className="text-sm">Tiền thưởng đang xử lý</span>
                    <span className="text-base font-semibold">
                      {formatCurrency(cashAsset?.bought_consideration)}
                    </span>
                  </li>
                  <li className="flex justify-between border-b border-gray py-3">
                    <span className="text-sm">Tiền rút đang xử lý</span>
                    <span className="text-base font-semibold">
                      {formatCurrency(cashAsset?.pending_withdraw_cash_bal)}
                    </span>
                  </li>
                </ul>
                <div className="mt-3 bg-blackBlue p-4">
                  <p className="text-text_main_light text-sm font-normal leading-5 mb-2">
                    Giao dịch ký quỹ là dịch vụ cho phép nhà đầu tư vay tiền với
                    tỷ lệ hỗ trợ của công ty chứng khoán mua cổ phiếu có sử dụng
                    hạn mức tín dụng bằng cách thế chấp tài sản đảm bảo.
                  </p>
                  {/* <a
                  className="text-primary text-sm flex items-center hover:underline"
                  href="#"
                  title="Tìm hiểu thêm"
                >
                  Tìm hiểu thêm
                  <RiArrowRightSLine className="text-[20px]" />
                </a> */}
                </div>
              </>
            ) : (
              <AccountNotification className="mt-[90px]" />
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex-1 bg-black rounded-lg border-style !border-t-0 ${
          !account ? 'flex items-center justify-center' : ''
        }`}
      >
        {account ? (
          <>
            <TitleSection title="Lịch sử sao kê tiền" />
            <CashHistories />
          </>
        ) : (
          <AccountNotification />
        )}
      </div>
    </div>
  );
};

export default AssetsPage;
