import React, { ReactNode, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { LoadingBarProcess, MasterLayout, SpinnerIcon } from 'components';
import CallBackPage from 'pages/callBack/CallBackPage';
import { PageNotFound } from 'pages/404/PageNotFound';
import { Page505 } from 'pages/404/Page505';
import { FirestoreService } from 'core/services/firestore.service';
import { AccountService } from 'core/services/account.service';
import { EKYC, STEP_EKYC, ACCOUNT_ID_TYPE } from 'core/constant';
import { Maintain } from 'pages/404/maintain';
import { useAccount } from 'core/contexts/AccountContext';
import { useLoading } from 'core/contexts';
import { LOGIN_KEY } from 'core/constant/storage.constant';
import LogoutPage from 'pages/logout/LogoutPage';
import { HomePageRoutes } from './PageRoutes';
import { MarketRoutes } from './MarketRoutes';
import { OrderBookRoutes } from './OrderBookRoutes';
import { StockOrderRoutes } from './StockOrderRoutes';
import { AssetsRoutes } from './AssetsRoutes';
import { AccountRoutes } from './AccountRoutes';
import { OrderHistoryRoutes } from './OrderHistoryRoutes';
import { CashHistoryRoutes } from './CashHistoryRoutes';
import DepositRoutes from './DepositRoutes';
import { ProfitRoutes } from './ProfitRoutes';
import { MarginRoutes } from './MarginRoutes';
import { CashRoutes } from './CashRoutes';
import TransferCashRoutes from './TransferCashRoutes';
import { RegisterRoutes } from './RegisterRoutes';
import { createSearchParams, useNavigate } from 'react-router-dom';
import CashAdvanceRoutes from './CashAdvanceRoutes';
import WithdrawRoutes from './WithdrawRoutes';

export const RequireAuth: React.FC<{ children: JSX.Element }> = ({
  children
}: {
  children: JSX.Element;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { account, accountInfo, updateAccountInfo, updateAccount } =
    useAccount();
  const [inited, setInited] = useState<boolean>(false);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      return;
    }

    init();
  }, [auth.isAuthenticated]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinRedirect();
    });
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth.activeNavigator === 'signinRedirect') {
      localStorage.setItem(LOGIN_KEY, 'signin');
    }
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();

      return;
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect
  ]);

  const handleUserInfoStatus = async () => {
    if (account && accountInfo) {
      return;
    }
    const accountRes = await AccountService.getAccountInfo();
    const accountData = accountRes?.data?.data;
    if (accountData) {
      const initAccount =
        accountData?.accounts?.find(
          (i: any) => i.account_type_id === ACCOUNT_ID_TYPE.NORMAL
        ) || accountData?.accounts?.[0];

      updateAccountInfo(accountData);
      updateAccount(initAccount);
      if (accountData?.pin && accountData?.sign_contract) {
        return;
      }
    }
  };

  const init = async () => {
    const maintainFlag =
      (await FirestoreService.getAppMaintainFlag()) === 'true';

    if (maintainFlag) {
      navigate('/maintain');
      return;
    }

    if (!auth.isAuthenticated) {
      return;
    }
    await handleUserInfoStatus();
    setInited(true);
  };

  if (auth.activeNavigator || auth.isLoading || !inited) {
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-white/40 z-50 flex items-center justify-center">
        <SpinnerIcon className="scale-75" />
      </div>
    );
  }

  if (auth.isAuthenticated) {
    return children;
  } else {
    return <div>Unable to log in!</div>;
  }
};

const PageRoutes = () => {
  const { isLoading } = useLoading();

  // useEffect(() => {
  //   ConfigService.getConfigAllType();
  // }, []);

  return (
    <>
      <Routes>
        {/* <Route path="/*" element={<HomePageRoutes />} /> */}
        <Route path="/callback" element={<CallBackPage />}></Route>
        <Route path="/logout" element={<LogoutPage />}></Route>
        <Route path="/market/*" element={<MarketRoutes />} />
        {/* <Route path="/order-book" element={<OrderBookRoutes />}></Route> */}
        <Route path="/stock-order" element={<StockOrderRoutes />}></Route>
        <Route path="/" element={<AssetsRoutes />}></Route>
        <Route path="/cash" element={<CashRoutes />}></Route>
        <Route path="/profit" element={<ProfitRoutes />}></Route>
        <Route path="/margin" element={<MarginRoutes />}></Route>
        <Route path="/account/*" element={<AccountRoutes />}></Route>
        <Route path="/order-history/*" element={<OrderHistoryRoutes />}></Route>
        <Route path="/cash-history/*" element={<CashHistoryRoutes />}></Route>
        <Route path="/deposit/*" element={<DepositRoutes />}></Route>
        <Route path="/withdraw/*" element={<WithdrawRoutes />}></Route>
        <Route path="/transfer-cash/*" element={<TransferCashRoutes />}></Route>
        <Route path="/register" element={<RegisterRoutes />}></Route>
        <Route path="/advance-cash/*" element={<CashAdvanceRoutes />}></Route>
        <Route path="/callback" element={<CallBackPage />}></Route>
        <Route path="/logout" element={<LogoutPage />}></Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/404"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <PageNotFound />
              </React.Suspense>
            }
          />
        </Route>
        <Route>
          <Route path="/maintain" element={<Maintain />} />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/505"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <Page505 />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-white/40 z-50 flex items-center justify-center">
          <SpinnerIcon className="scale-75" />
        </div>
      )}
    </>
  );
};

export default PageRoutes;
