import { Menu, Transition } from '@headlessui/react';
import CommandHistoryTable from 'components/OrderHistoryTable';
import TransactionTypeItem from 'components/TransactionTypeItem';
import { ConfigService, MarketStockService } from 'core';
import { useAccount } from 'core/contexts';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { DatePicker, Radio } from 'antd';
import { RiArrowDownSFill, RiCloseFill } from 'react-icons/ri';
import {
  AccountNotification,
  NoAccount,
  SpinnerIcon,
  TitleSection
} from 'components';
import {
  EKYC,
  MTA_ACCOUNT_DESCRIPTION_MARGIN,
  MTA_ACCOUNT_DESCRIPTION_NORMAL
} from 'core/constant';
import { ppid } from 'process';

const { RangePicker } = DatePicker;

const FillterItemStyle =
  'px-2 py-1 flex gap-2 items-center rounded border border-solid border-[#1D2939] cursor-pointer';

const orderTypeData = [
  {
    id: 1,
    value: 'Buy',
    label: 'Mua'
  },
  {
    id: 2,
    value: 'Sell',
    label: 'Bán'
  },
  {
    id: 3,
    value: '',
    label: 'Tất cả'
  }
];

const timeData = [
  {
    id: 1,
    value: 'All',
    label: 'Tất cả'
  },
  {
    id: 2,
    value: 'Week',
    label: 'Tuần này'
  },
  {
    id: 3,
    value: 'Month',
    label: 'Tháng này'
  },
  {
    id: 4,
    value: 'LastMonth',
    label: 'Tháng trước'
  },
  {
    id: 5,
    value: 'Custom',
    label: 'Tự chọn'
  }
];

const statusData = [
  {
    id: 1,
    value: 'all',
    label: 'Tất cả'
  },
  {
    id: 2,
    value: 'match',
    label: 'Khớp'
  },
  {
    id: 3,
    value: 'cancel',
    label: 'Hủy'
  }
];

const OrderHistory = () => {
  const [fillter, setFillter] = useState<any>([]);
  const { account, userInfo, accountInfo } = useAccount();
  const [accountData, setAccountData] = useState<any>();
  const [transactionType, setTransactionType] = useState<string>();
  const [orderBook, setOrderBook] = useState<any>([]);
  const [fromDate, setFromDate] = useState<any>(
    moment().subtract(14, 'days').format('YYYY-MM-DDT00:00:00')
  );
  const [toDate, setToDate] = useState<any>(
    moment().subtract(1, 'days').format('YYYY-MM-DDT23:59:59')
  );
  const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowOrderType, setIsShowOrderType] = useState<boolean>(false);
  const [isShowStatus, setIsShowStatus] = useState<boolean>(false);
  const [isShowTime, setIsShowTime] = useState<boolean>(false);

  const onSelectItem = (stockCode: string) => {
    if (stockCode === 'M') {
      setAccountData(
        accountInfo?.accounts?.find((item: any) => item.account_type_id === 'M')
      );
      setTransactionType('M');
    } else {
      setAccountData(
        accountInfo?.accounts?.find((item: any) => item.account_type_id === 'C')
      );
      setTransactionType('C');
    }
  };

  const handChangeComandType = (event: any) => {
    setFillter((prevState: any) => {
      return {
        ...prevState,
        commandType: {
          value: event.target.value || '',
          label:
            event.target.value === 'Buy'
              ? 'Mua'
              : event.target.value === 'Sell'
              ? 'Bán'
              : 'Tất cả'
        }
      };
    });
  };

  const handChangeStatus = (event: any) => {
    setFillter((prevState: any) => {
      return {
        ...prevState,
        status: {
          value: event.target.value,
          label:
            event.target.value === 'match'
              ? 'Khớp'
              : event.target.value === 'cancel'
              ? 'Hủy'
              : 'Tất cả'
        }
      };
    });
  };

  const handleChangeTime = (event: any) => {
    if (event.target.value === 'Week') {
      const _fromDate = moment()
        .startOf('isoWeek')
        .format('YYYY-MM-DDT00:00:00');
      const _toDate = moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DDT23:59:59');
      if (moment(_fromDate).isoWeekday() === 1) {
        setFromDate(
          moment()
            .subtract(7, 'days')
            .startOf('isoWeek')
            .format('YYYY-MM-DDT00:00:00')
        );
      } else {
        setFromDate(_fromDate);
      }
      setToDate(_toDate);
    }
    if (event.target.value === 'Month') {
      setFromDate(moment().startOf('month').format('YYYY-MM-DDT00:00:00'));
      setToDate(moment().subtract(1, 'days').format('YYYY-MM-DDT23:59:59'));
    }
    if (event.target.value === 'LastMonth') {
      setFromDate(
        moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DDT00:00:00')
      );
      setToDate(
        moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DDT23:59:59')
      );
    }
    if (event.target.value === 'Custom') {
      setIsShowDatePicker(true);
    } else {
      setIsShowDatePicker(false);
    }
    if (event.target.value === 'All') {
      setFromDate('');
      setToDate('');
    }

    setFillter((prevState: any) => {
      return {
        ...prevState,
        time: {
          value: event.target.value,
          label:
            event.target.value === 'All'
              ? 'Tất cả'
              : event.target.value === 'Week'
              ? 'Tuần này'
              : event.target.value === 'Month'
              ? 'Tháng này'
              : event.target.value === 'LastMonth'
              ? 'Tháng trước'
              : (event.target.fromDate
                  ? moment(event.target.fromDate).format('DD/MM/YYYY')
                  : fromDate
                  ? moment(fromDate).format('DD/MM/YYYY')
                  : 'Từ ngày') +
                ' - ' +
                (event.target.toDate
                  ? moment(event.target.toDate).format('DD/MM/YYYY')
                  : toDate
                  ? moment(toDate).format('DD/MM/YYYY')
                  : 'Đến ngày')
        }
      };
    });
  };

  const handleClearFillter = () => {
    setFillter((prevState: any) => ({
      ...prevState,
      commandType: null,
      status: null,
      time: null
    }));

    setFromDate(moment().subtract(14, 'days').format('YYYY-MM-DDT00:00:00'));
    setToDate(moment().subtract(1, 'days').format('YYYY-MM-DDT23:59:59'));
    setIsShowDatePicker(false);
  };

  const fetchOrderBook = async (params: any) => {
    setLoading(true);
    if (account) {
      const response = await MarketStockService.getOrderBook(params);
      if (response?.data) {
        const data = response?.data?.data;
        setOrderBook(data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.append(
      'accountNo',
      accountData?.account_id || account?.account_id
    );
    if (fillter?.commandType?.value) {
      searchParams.append('orderType', fillter?.commandType?.value);
    }
    if (fillter?.status?.value === 'match') {
      searchParams.append('orderStatuses', 'FullFill');
      searchParams.append('orderStatuses', 'PartialFill');
    } else if (fillter?.status?.value === 'cancel') {
      searchParams.append('orderStatuses', 'Denied');
      searchParams.append('orderStatuses', 'Canceled');
      searchParams.append('orderStatuses', 'Expired');
    }
    if (fromDate) {
      searchParams.append('fromDate', fromDate);
    }
    if (toDate) {
      searchParams.append('toDate', toDate);
    }
    searchParams.append('pageIndex', '1');
    searchParams.append('pageSize', '1000');

    fetchOrderBook(searchParams);
  }, [
    accountData,
    fillter?.commandType?.value,
    fillter?.status?.value,
    fromDate,
    toDate
  ]);

  useEffect(() => {
    if (account) {
      setAccountData(account);
      setTransactionType(account?.account_type_id);
    }
  }, []);

  const handleHideDropdown = () => {
    setIsShowOrderType(false);
    setIsShowStatus(false);
    setIsShowTime(false);
  };

  return (
    <div className="h-full p-2" onClick={handleHideDropdown}>
      <div className="bg-black border-style !border-t-0 rounded-lg pb-[127px] h-full">
        <TitleSection title="Lịch sử lệnh" />
        <div className="my-4">
          <div className="py-2 px-4 flex gap-2">
            <Menu as="div" className="relative inline-block">
              <Menu.Button>
                <div className={`${FillterItemStyle} bg-primary`}>
                  <p className="text-sm font-semibold leading-5">
                    {transactionType === 'M' ? 'Ký quỹ' : 'Thường'}
                  </p>
                  <RiArrowDownSFill className="text-sm" />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute top-[33px] left-0 w-[370px] bg-black p-2 rounded-md text-text_main_dark border border-solid border-gray">
                  <TransactionTypeItem
                    stockCode="M"
                    stockName="Ký quỹ"
                    description={MTA_ACCOUNT_DESCRIPTION_MARGIN}
                    isSelected={transactionType === 'M'}
                    onSelectItem={onSelectItem}
                  />
                  <TransactionTypeItem
                    stockCode="C"
                    stockName="Thường"
                    description={MTA_ACCOUNT_DESCRIPTION_NORMAL}
                    isSelected={transactionType === 'C'}
                    onSelectItem={onSelectItem}
                  />
                </div>
              </Transition>
            </Menu>
            <Menu as="div" className="relative inline-block">
              <Menu.Button>
                <div
                  className={FillterItemStyle}
                  onClick={e => {
                    e.stopPropagation();
                    setIsShowOrderType(!isShowOrderType);
                    setIsShowStatus(false);
                    setIsShowTime(false);
                  }}
                >
                  <p className="text-sm font-semibold leading-5">Loại lệnh</p>
                  <RiArrowDownSFill className="text-sm" />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={isShowOrderType}
              >
                <div
                  className="absolute top-[33px] left-0 w-[230px] bg-black p-2 rounded-md text-text_main_dark border border-solid border-tab_background"
                  onClick={() => {
                    setIsShowOrderType(false);
                  }}
                >
                  <Radio.Group
                    value={fillter?.commandType?.value}
                    onChange={handChangeComandType}
                    className="flex flex-col text-xs font-normal leading-[18px] tracking-[-0.6px]"
                  >
                    {orderTypeData?.map(item => {
                      return (
                        <Radio
                          key={item.id}
                          value={item?.value}
                          className="radio-button-style"
                        >
                          {item.label}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </div>
              </Transition>
            </Menu>
            <Menu as="div" className="relative inline-block">
              <Menu.Button>
                <div
                  className={`${FillterItemStyle}`}
                  onClick={e => {
                    e.stopPropagation();
                    setIsShowStatus(!isShowStatus);
                    setIsShowOrderType(false);
                    setIsShowTime(false);
                  }}
                >
                  <p className="text-sm font-semibold leading-5">Trạng thái</p>
                  <RiArrowDownSFill className="text-sm" />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={isShowStatus}
              >
                <div
                  className="absolute top-[33px] left-0 w-[260px] bg-black p-2 rounded-md text-text_main_dark border border-solid border-tab_background"
                  onClick={() => {
                    setIsShowStatus(false);
                  }}
                >
                  <Radio.Group
                    value={fillter?.status?.value}
                    onChange={handChangeStatus}
                    className="flex flex-col text-xs font-normal leading-[18px] tracking-[-0.6px]"
                  >
                    {statusData?.map(status => {
                      return (
                        <Radio
                          key={status.id}
                          value={status?.value}
                          className="radio-button-style"
                        >
                          {status.label}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </div>
              </Transition>
            </Menu>

            <Menu as="div" className="relative inline-block">
              <Menu.Button>
                <div
                  className={FillterItemStyle}
                  onClick={e => {
                    e.stopPropagation();
                    setIsShowTime(!isShowTime);
                    setIsShowOrderType(false);
                    setIsShowStatus(false);
                  }}
                >
                  <p className="text-sm font-semibold leading-5">Thời gian</p>
                  <RiArrowDownSFill className="text-sm" />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={isShowTime}
              >
                <div
                  className="absolute top-[33px] left-0 w-[230px] bg-black p-2 rounded-md text-text_main_dark border border-solid border-tab_background"
                  onClick={() => {
                    setIsShowTime(false);
                  }}
                >
                  <Radio.Group
                    value={fillter?.time?.value}
                    onChange={handleChangeTime}
                    className="flex flex-col text-xs font-normal leading-[18px] tracking-[-0.6px]"
                  >
                    {timeData?.map(time => {
                      return (
                        <Radio
                          key={time.id}
                          value={time?.value}
                          className="radio-button-style"
                        >
                          {time.label}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </div>
              </Transition>
            </Menu>
            {isShowDatePicker && (
              <div className="datepicker relative">
                <RangePicker
                  onChange={(dates: any, dateStrings: any) => {
                    if (dateStrings[0] && dateStrings[1]) {
                      setFromDate(dateStrings[0] + 'T00:00:00');
                      setToDate(dateStrings[1] + 'T23:59:59');
                      handleChangeTime({
                        target: {
                          value: 'Custom',
                          fromDate: dateStrings[0],
                          toDate: dateStrings[1]
                        }
                      });
                      setIsShowDatePicker(false);
                    }
                  }}
                  // disable today and future date
                  disabledDate={current => {
                    return current && current > moment().startOf('day');
                  }}
                  placeholder={['Từ ngày', 'Đến ngày']}
                  className="datepicker__input opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                />
                <div className="flex items-center gap-[2px] px-2 py-1 border border-solid border-tab_background bg-black rounded">
                  <p className="text-sm font-semibold leading-5">
                    {fillter?.time?.value === 'Custom'
                      ? 'Ngày GD: ' +
                        (fromDate
                          ? moment(fromDate).format('DD/MM/YYYY')
                          : 'Từ ngày') +
                        ' - ' +
                        (toDate
                          ? moment(toDate).format('DD/MM/YYYY')
                          : 'Đến ngày')
                      : fillter?.time?.label}
                  </p>
                  <RiArrowDownSFill className="text-sm" />
                </div>
              </div>
            )}
          </div>
          <div className="py-2 px-4 flex gap-2 text-sm font-semibold leading-5">
            {(fillter?.commandType || fillter?.status || fillter?.time) && (
              <div
                className="px-2 h-7 text-primary flex items-center rounded border border-solid border-primary cursor-pointer"
                onClick={handleClearFillter}
              >
                <p>Xóa hết</p>
              </div>
            )}
            {fillter?.commandType && (
              <div className="px-2 h-7 text-text_main_light flex gap-2 justify-center items-center rounded bg-blackBlue cursor-pointer">
                <p>{fillter?.commandType?.label}</p>
                <div
                  onClick={() => {
                    setFillter((prevState: any) => {
                      return {
                        ...prevState,
                        commandType: null
                      };
                    });
                  }}
                >
                  <RiCloseFill className="text-sm" />
                </div>
              </div>
            )}
            {fillter?.status && (
              <div className="px-2 h-7 text-text_main_light flex gap-2 justify-center items-center rounded bg-blackBlue cursor-pointer">
                <p>{fillter?.status?.label}</p>
                <div
                  onClick={() => {
                    setFillter((prevState: any) => {
                      return {
                        ...prevState,
                        status: null
                      };
                    });
                  }}
                >
                  <RiCloseFill className="text-sm" />
                </div>
              </div>
            )}
            {fillter?.time && (
              <div className="px-2 h-7 text-text_main_light flex gap-2 justify-center items-center rounded bg-blackBlue cursor-pointer">
                <p>{fillter?.time?.label}</p>
                <div
                  onClick={() => {
                    setFillter((prevState: any) => {
                      return {
                        ...prevState,
                        time: null
                      };
                    });
                    setFromDate(
                      moment()
                        .subtract(14, 'days')
                        .format('YYYY-MM-DDT00:00:00')
                    );
                    setToDate(
                      moment().subtract(1, 'days').format('YYYY-MM-DDT23:59:59')
                    );
                    setIsShowDatePicker(false);
                  }}
                >
                  <RiCloseFill className="text-sm" />
                </div>
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <SpinnerIcon className="scale-75" />
          </div>
        ) : (
          <>
            <div className={`px-4`}>
              <CommandHistoryTable data={orderBook?.items} />
            </div>
            {!account && (
              <div className="flex justify-center items-center pt-[157px]">
                <AccountNotification />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderHistory;
