import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { RiInformationLine } from 'react-icons/ri';
import { styled } from '@mui/material/styles';

type Props = {
  title: string;
  placement?: any;
  className?: string;
};

export const InfoTooltip = styled(
  ({ title, placement = 'bottom-start', className, ...props }: Props) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      title={
        <div
          className="text-sm"
          dangerouslySetInnerHTML={{
            __html: title
              ?.replaceAll('**:', ': </strong>')
              .replaceAll(':**', ': </strong>')
              .replaceAll('**', '<strong>')
              .replace(/\n/g, '<br>')
          }}
        ></div>
      }
    >
      <button>
        <RiInformationLine className="text-gray_dark text-xs" />
      </button>
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0C111D',
    color: '#fff',
    maxWidth: 220,
    border: '1px solid #475467',
    borderRadius: 6
  }
}));
