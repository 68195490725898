import { TRADE, BIZ } from '../constant/api.constant';
import axiosInstance from './restful.service';

const getTotalAssets = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(TRADE.TOTAL_ASSET, { params });
};
const getTotalAssetsDistribution = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(TRADE.TOTAL_ASSET_DISTRIBUTION, { params });
};

const getAccountCashDistribution = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(BIZ.CASH_DISTRIBUTION, { params });
};

const getStockPortfolio = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(TRADE.STOCK_PORTFOLIO, { params });
};

const getCashPortfolio = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(TRADE.CASH_PORTFOLIO, { params });
};

const getCashHistories = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(TRADE.CASH_HISTORIES, { params });
};

const cashTransferInside = (params: any) => {
  return axiosInstance.post(TRADE.CASH_TRANSFER_INSIDE, params);
};

const getCashStatement = (params: any) => {
  return axiosInstance.get(BIZ.CASH_STATEMENT, { params });
};

const getAvailableCia = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(BIZ.AVAILABLE_CIA, { params });
};

const createCia = (params: any) => {
  return axiosInstance.post(BIZ.CREATE_CIA, params);
};

const ciaInfo = (params: any) => {
  return axiosInstance.get(BIZ.CIA_INFO, { params });
};

export const AssetsService = {
  getTotalAssets,
  getStockPortfolio,
  getCashPortfolio,
  getCashHistories,
  getTotalAssetsDistribution,
  getAccountCashDistribution,
  cashTransferInside,
  getCashStatement,
  getAvailableCia,
  createCia,
  ciaInfo
};
