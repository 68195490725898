import React from 'react';
import { StockSymbol } from 'components/stockSymbol/StockSymbol';
import StockList from './components/StockList';
import MovementList from './components/MovementList';

const MarketOverview = () => {
  return (
    <>
      <div className="border-style px-4 py-2 flex gap-[155.5px]">
        <p className="text-base font-semibold leading-[22px]">Các chỉ số</p>
      </div>
      <div className="px-4 py-3">
        <StockSymbol />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <StockList />
        <MovementList />
      </div>
    </>
  );
};

export default MarketOverview;
