import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useSearchParams } from 'react-router-dom';
import { Footer } from './Footer';
import HeaderNav from './HeaderNav';
import SideBar from './SideBar';
import StockDetailModel from 'components/StockDetail/StockDetailModel';

export interface MasterLayoutProps {
  type: string;
}

export const MasterLayout = () => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const stock = searchParams.get('stock') || '';

  useEffect(() => {
    setIsAuthenticated(auth?.isAuthenticated);
  }, [auth.isAuthenticated]);

  return (
    <>
      {isAuthenticated && <HeaderNav />}
      <main className="min-h-screen pt-[70px] pb-[28px] flex">
        <SideBar />
        <section className="flex-1 bg-blackBlue">
          <Outlet />
        </section>
      </main>
      <Footer />
      <StockDetailModel
        isShowStockDetailModel={!!stock}
        setIsShowStockDetailModel={() => {
          if (searchParams.has('stock')) {
            searchParams.delete('stock');
            setSearchParams(searchParams);
          }
        }}
        stockCode={stock}
      />
    </>
  );
};
