import React, { useState, useEffect } from 'react';
import { useAccount } from 'core/contexts/AccountContext';
import AssetTable from './AssetsTable';
import { AssetsService } from 'core/services';
import { formatCurrency } from 'core/utils';

const StockPortfolio = () => {
  const { account } = useAccount();
  const [stockPortfolioData, setStockPortfolioData] = useState<any>();

  const fetchStockPortfolio = async () => {
    if (account) {
      const res = await AssetsService.getStockPortfolio(account.account_id);
      const data = res?.data?.data;
      setStockPortfolioData(data);
    }
  };

  useEffect(() => {
    fetchStockPortfolio();
  }, [account]);

  return <AssetTable data={stockPortfolioData} />;
};

export default StockPortfolio;
