import { formatStockPrice, getColorStatusOrder } from 'core/utils';
import moment from 'moment';
import React from 'react';

type OrderHistoryRowProps = {
  data: any;
};

const smallTextStyle =
  'text-xs font-normal leading-[18px] tracking-[-0.6px] text-cadetGrey mt-1';

const OrderHistoryRow = ({ data }: OrderHistoryRowProps) => {
  return data ? (
    <tr>
      <td>
        <p>{data.stock_symbol}</p>
        <p className={smallTextStyle}>
          {data.input_date
            ? moment(new Date(data.input_date)).format('DD/MM HH:mm')
            : ''}
        </p>
      </td>
      <td className='flex'>
        <p
          className={`${
            data.bid_ask_type === 'B'
              ? 'text-[#00C802]'
              : data.bid_ask_type === 'S'
              ? 'text-[#F04438]'
              : ''
          }`}
        >
          {data.bid_ask_type === 'B'
            ? 'Mua'
            : data.bid_ask_type === 'S'
            ? 'Bán'
            : ''}
        </p>
      </td>
      <td>
        <p>
          {data.filled_quantity !== null && data.filled_quantity !== 0
            ? data.filled_quantity.toLocaleString('en-US')
            : '--'}
        </p>
        <p className={smallTextStyle}>
          {data.order_quantity !== null
            ? data.order_quantity.toLocaleString('en-US')
            : '--'}
        </p>
      </td>
      <td>
        <p>
          {data.avg_price !== null ? formatStockPrice(data.avg_price) : '--'}
        </p>
        <p className={smallTextStyle}>{data.order_price}</p>
      </td>
      <td>
        <span className={`text-xs ${getColorStatusOrder(data.display_status)}`}>
          {data.display_status_name}
        </span>
      </td>
    </tr>
  ) : (
    <tr>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
    </tr>
  );
};

export default OrderHistoryRow;
