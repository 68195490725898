import React from 'react';

type ChartIconProps = {
  color?: string;
  className?: string;
};

export const ChartIcon = ({ className, color }: ChartIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M2.55 12.05H4.45V20.95H2.55V12.05ZM5.55 14.05H7.45V20.95H5.55V14.05ZM16.55 8.05H18.45V20.95H16.55V8.05ZM19.55 10.05H21.45V20.95H19.55V10.05ZM9.55 2.05H11.45V20.95H9.55V2.05ZM12.55 4.05H14.45V20.95H12.55V4.05Z"
        fill={`${color || '#475467'}`}
        stroke="#475467"
        strokeWidth="0.1"
      />
    </svg>
  );
};
