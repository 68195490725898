import React, { useEffect, useState } from 'react';
import StockItem from 'components/StockItem';
import StockTable from 'components/StockTable';
import { MarketStockService } from 'core';
import { STOCK_MARKET_TOP } from 'core/constant';
import { useSearchParams } from 'react-router-dom';

type Props = {
  label: string;
  value: STOCK_MARKET_TOP;
  filter: any[];
};

const StockMovementTable = ({ label, value, filter }: Props) => {
  const [marketFilter, setMarketFilter] = useState<any>(filter[0].value);
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchMarketStock();
  }, [marketFilter]);

  const fetchMarketStock = async () => {
    let type = value;
    let period = marketFilter;

    if (
      type === STOCK_MARKET_TOP.PEAK_52_WEEKS ||
      type === STOCK_MARKET_TOP.BOTTOM_52_WEEK
    ) {
      type = marketFilter;
      period = undefined;
    }
    const res = await MarketStockService.getMarketStock(type, period, 30);
    const data = res?.data?.data?.items;
    setData(data);
  };

  return (
    <div className="hidden-scrollbar border-style flex flex-col gap-4">
      <div className="border-bottom-style px-4 py-2">
        <p className="text-base font-semibold leading-[22px]">{label}</p>
      </div>
      <div className="px-4 py-1 flex gap-x-2">
        {filter.map((i, index) => (
          <StockItem
            key={index}
            isActived={marketFilter === i.value}
            handleClickStock={() => {
              setMarketFilter(i.value);
            }}
          >
            {i.label}
          </StockItem>
        ))}
      </div>
      <div className="h-[380px] pb-8">
        <StockTable
          showFull
          data={data}
          isRefreshWhenSort
          handleClickStock={(stockCode: string) => {
            searchParams.append('stock', stockCode);
            setSearchParams(searchParams);
          }}
        />
      </div>
    </div>
  );
};

export default StockMovementTable;
