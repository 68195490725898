import { initializeApp } from 'firebase/app';
import { doc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { collection } from 'firebase/firestore';
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
  getString
} from 'firebase/remote-config';

const firebaseConfig = {
  type: 'service_account',
  projectId: process.env.REACT_APP_projectId,
  clientId: process.env.REACT_APP_clientId,
  authUri: process.env.REACT_APP_authUri,
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_API_APP_ID,
  authDomain: process.env.REACT_APP_projectId + '.firebaseapp.com',
  storageBucket: process.env.REACT_APP_projectId + '.appspot.com',
  databaseURL: 'https://' + process.env.REACT_APP_projectId + '.firebaseio.com'
};

const app = initializeApp(firebaseConfig);

const config = getRemoteConfig(app);

config.settings.minimumFetchIntervalMillis = 300000;

let rcDefaults = require('./remote_config_defaults.json');
config.defaultConfig = rcDefaults;

var db: any;

const connectStore = () => {
  db = getFirestore(app);
};

const getStockExchange = () => {
  if (!db) {
    connectStore();
  }
  return collection(db, 'stock_exchange');
};

const getListVNINDEX = () => {
  if (!db) {
    connectStore();
  }
  return getDocs(query(collection(db, 'stock_symbol')));
};

const getStockSymbolTheoDoi = (symbols: string[]) => {
  if (!db) {
    connectStore();
  }
  return getDocs(
    query(collection(db, 'stock_symbol'), where('symbol', 'in', symbols))
  );
};

const getStockBySymbol = (symbol: string) => {
  if (!db) {
    connectStore();
  }
  return query(collection(db, 'stock_symbol'), where('symbol', '==', symbol));
};

const readStockSymbol = (symbol: string) => {
  if (!db) {
    connectStore();
  }
  const docRef = doc(db, 'stock_symbol', symbol);
  return docRef;
};

const getAppMaintainFlag = async () => {
  return getString(config, 'APP_MAINTAIN');
};

let apiPublicKey: string | null = null;

const getAPIPublicKey = () => {
  if (!apiPublicKey) {
    apiPublicKey = getString(config, 'ENCRYPT_APIDATA_PUBLICKEY');
  }
  return apiPublicKey;
};

let encryptAesKey: string | null = null;

const getEncryptAesKey = () => {
  if (!encryptAesKey) {
    encryptAesKey = getString(config, 'FE_ENCRYPT_AES_KEY');
  }

  return encryptAesKey;
};

const getStocksByMarketId = (marketId: string) => {
  if (!db) {
    connectStore();
  }
  return getDocs(
    query(
      collection(db, 'stock_symbol'),
      where('instrument_type_id', '==', 'STOCK'),
      where('market_id', '==', marketId)
    )
  );
};

const getStocksByGroup = (group: string) => {
  if (!db) {
    connectStore();
  }
  return getDocs(
    query(
      collection(db, 'stock_symbol'),
      where('instrument_type_id', '==', 'STOCK'),
      where(group, '==', true)
    )
  );
};

const getStocksETF = () => {
  if (!db) {
    connectStore();
  }
  return getDocs(
    query(
      collection(db, 'stock_symbol'),
      where('instrument_type_id', '==', 'ETF')
    )
  );
};

export const FirestoreService = {
  getAPIPublicKey,
  getEncryptAesKey,
  getStockExchange,
  getAppMaintainFlag,
  getStockSymbolTheoDoi,
  getStockBySymbol,
  getListVNINDEX,
  readStockSymbol,
  getStocksByMarketId,
  getStocksETF,
  getStocksByGroup
};
