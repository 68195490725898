import React, { useEffect, useState } from 'react';
import StockTable from 'components/StockTable';
import { STOCK_MARKET_ID } from 'core/index';
import StockItem from 'components/StockItem';
import { useAccount } from 'core/contexts/AccountContext';
import { FirestoreService } from 'core';
import { useSearchParams } from 'react-router-dom';

const StockList = () => {
  const { watchlist } = useAccount();
  const [listStock, setListStock] = useState<any>([]);
  const [marketId, setMarketId] = useState<any>(STOCK_MARKET_ID.WATCH_LIST);
  const [searchParams, setSearchParams] = useSearchParams();

  const getListStockByMarket = async (market: string) => {
    const responseStocks = await FirestoreService.getStocksByMarketId(market);
    const data = responseStocks?.docs.map(
      (docSnapshot: any) => docSnapshot.data() || []
    );
    return data;
  };

  const getStocksByGroup = async (group: string) => {
    const responseStocks = await FirestoreService.getStocksByGroup(group);
    const data = responseStocks?.docs.map(
      (docSnapshot: any) => docSnapshot.data() || []
    );
    return data;
  };

  const getListStockETF = async () => {
    const responseStocks = await FirestoreService.getStocksETF();
    const data = responseStocks?.docs.map(
      (docSnapshot: any) => docSnapshot.data() || []
    );
    return data;
  };

  const getListStock = async () => {
    if (marketId === STOCK_MARKET_ID.WATCH_LIST) {
      setListStock(watchlist);
    }
    if (
      marketId === STOCK_MARKET_ID.VN30 ||
      marketId === STOCK_MARKET_ID.HNX30
    ) {
      const data = await getStocksByGroup(marketId);
      setListStock(data);
    }
    if (
      marketId === STOCK_MARKET_ID.HOSE ||
      marketId === STOCK_MARKET_ID.HNX ||
      marketId === STOCK_MARKET_ID.UPCOM
    ) {
      const data = await getListStockByMarket(marketId);
      setListStock(data);
    }
    if (marketId === STOCK_MARKET_ID.ETF) {
      const data = await getListStockETF();
      setListStock(data);
    }
  };

  useEffect(() => {
    getListStock();
  }, [marketId]);

  useEffect(() => {
    if (marketId === STOCK_MARKET_ID.WATCH_LIST) {
      setListStock(watchlist);
    }
  }, [watchlist]);

  return (
    <div className="border-style flex flex-col gap-4">
      <div className="border-bottom-style px-4 py-2">
        <p className="text-base font-semibold leading-[22px]">
          Danh sách cổ phiếu
        </p>
      </div>
      <div className="px-4 py-1 flex gap-x-2">
        <StockItem
          isActived={marketId === STOCK_MARKET_ID.WATCH_LIST}
          handleClickStock={() => {
            setMarketId(STOCK_MARKET_ID.WATCH_LIST);
          }}
        >
          Theo dõi
        </StockItem>
        <StockItem
          isActived={marketId === STOCK_MARKET_ID.VN30}
          handleClickStock={() => {
            setMarketId(STOCK_MARKET_ID.VN30);
          }}
        >
          VN30
        </StockItem>
        <StockItem
          isActived={marketId === STOCK_MARKET_ID.HNX30}
          handleClickStock={() => {
            setMarketId(STOCK_MARKET_ID.HNX30);
          }}
        >
          HNX30
        </StockItem>
        <StockItem
          isActived={marketId === STOCK_MARKET_ID.HOSE}
          handleClickStock={() => {
            setMarketId(STOCK_MARKET_ID.HOSE);
          }}
        >
          HOSE
        </StockItem>
        <StockItem
          isActived={marketId === STOCK_MARKET_ID.HNX}
          handleClickStock={() => {
            setMarketId(STOCK_MARKET_ID.HNX);
          }}
        >
          HNX
        </StockItem>
        <StockItem
          isActived={marketId === STOCK_MARKET_ID.UPCOM}
          handleClickStock={() => {
            setMarketId(STOCK_MARKET_ID.UPCOM);
          }}
        >
          UPCOM
        </StockItem>
        <StockItem
          isActived={marketId === STOCK_MARKET_ID.ETF}
          handleClickStock={() => {
            setMarketId(STOCK_MARKET_ID.ETF);
          }}
        >
          ETF
        </StockItem>
      </div>
      <div className="h-[630px]">
        <StockTable
          showFull
          data={listStock}
          isRefreshWhenSort={marketId === STOCK_MARKET_ID.WATCH_LIST}
          handleClickStock={(stockCode: string) => {
            searchParams.append('stock', stockCode);
            setSearchParams(searchParams);
          }}
        />
      </div>
    </div>
  );
};

export default StockList;
