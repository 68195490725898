import React from 'react';

type MultiCircleBoxProps = {
  children: React.ReactNode;
  className?: string;
};

const circleStyle =
  'rounded-full border stroke-[1px] border-solid flex justify-center items-center';

export const MultiCircleBox = ({ children }: MultiCircleBoxProps) => {
  return (
    <div className='absolute -z-20 top-[-70px] left-[-70px]'>
      <div className={`${circleStyle} w-[240px] h-[240px] border-[#1D2939]`}>
        <div className={`${circleStyle} w-[192px] h-[192px] border-[#344054]`}>
          <div
            className={`${circleStyle} w-[144px] h-[144px] border-[#475467]`}
          >
            <div
              className={`${circleStyle} w-[96px] h-[96px]  border-[#667085]`}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
