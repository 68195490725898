import React, { useEffect, useState } from 'react';
import StockTable from 'components/StockTable';
import {
  STOCK_MARKET_TOP,
  STOCK_MARKET_PERIOD,
  STOCK_MARKET_TOP_LIST
} from 'core/constant';
import StockItem from 'components/StockItem';
import { MarketStockService } from 'core';
import { useSearchParams } from 'react-router-dom';

const MovementList = () => {
  const [marketMovement, setMarketMovement] = useState<any>(
    STOCK_MARKET_TOP.TOP_INCREASE
  );
  const [marketMovementData, setMarketMovementData] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchMarketStock();
  }, [marketMovement]);

  const fetchMarketStock = async () => {
    const res = await MarketStockService.getMarketStock(
      marketMovement,
      STOCK_MARKET_PERIOD.ONE_DAY,
      5
    );
    const data = res?.data?.data;
    setMarketMovementData(data);
  };

  return (
    <div className="border-style flex flex-col gap-4">
      <div className="border-bottom-style px-4 py-2">
        <p className="text-base font-semibold leading-[22px]">
          Diễn biến thị trường
        </p>
      </div>
      <div className="pl-4 py-1">
        <div className="gap-x-2 flex">
          {STOCK_MARKET_TOP_LIST.map(i => (
            <StockItem
              key={i.value}
              isActived={marketMovement === i.value}
              handleClickStock={() => {
                setMarketMovement(i.value);
              }}
            >
              {i.label}
            </StockItem>
          ))}
        </div>
      </div>
      <div className="h-[630px]">
        <StockTable
          showFull
          isRefreshWhenSort
          data={marketMovementData?.items}
          handleClickStock={(stockCode: string) => {
            searchParams.append('stock', stockCode);
            setSearchParams(searchParams);
          }}
        />
      </div>
    </div>
  );
};

export default MovementList;
