import React, { useState, ReactNode, useEffect, useMemo } from 'react';
import { USER_PROFILE_KEY } from 'core/constant/storage.constant';
import { AccountInfo, AccountItem, UserInfo } from 'core/types';

interface AccountContextType {
  account?: AccountItem;
  updateAccount: (data: AccountItem) => void;
  accountInfo?: AccountInfo;
  userInfo?: UserInfo;
  updateUserInfo: (data: UserInfo) => void;
  watchlist: any[];
  updateWatchlist: (data?: any) => void;
  updateAccountInfo: (data?: any) => void;
}

type Props = {
  children: ReactNode;
};

const AccountContext = React.createContext<AccountContextType>(
  {} as AccountContextType
);

export const AccountProvider: React.FC<Props> = ({ children }) => {
  const [account, setAccount] = useState<AccountItem>();
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [watchlist, setwatchlist] = useState<any[]>([]);

  const updateAccount = (data: AccountItem) => {
    setAccount(data);
  };

  const updateAccountInfo = (data: AccountInfo) => {
    setAccountInfo(data);
  };

  const updateUserInfo = (data: UserInfo) => {
    setUserInfo(data);
  };

  const updateWatchlist = (data: any[]) => {
    const formatData = data.map(i => ({
      symbol: i,
      isFollowed: true
    }));
    setwatchlist(formatData);
  };

  const value = useMemo(
    () => ({
      account,
      updateAccount,
      accountInfo,
      updateAccountInfo,
      userInfo,
      updateUserInfo,
      watchlist,
      updateWatchlist
    }),
    [
      account,
      updateAccount,
      accountInfo,
      updateAccountInfo,
      userInfo,
      updateUserInfo,
      watchlist,
      updateWatchlist
    ]
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export function useAccount() {
  return React.useContext(AccountContext);
}

export default AccountContext;
