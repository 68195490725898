import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import VectorIcon from 'assets/images/vector_icon.png';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const VerifyAccountModal = ({ isOpen, closeModal }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title={''}
      typePopup="none"
      closeModal={closeModal}
      hasCloseBtn
      className={`max-w-[640px] !pt-6`}
    >
      <div
        className={`flex flex-col items-center text-center max-w-[359px] mx-auto py-10`}
      >
        <img
          src={VectorIcon}
          width={120}
          alt="Vui lòng hoàn thiện yêu cầu mở tài khoản"
        />
        <p className="text-sm font-normal leading-[22px] text-text_main_light my-4">
          SBBS đang xem xét yêu cầu của bạn. Chúng tôi sẽ gửi thông báo cho bạn
          khi hoàn tất.
        </p>
      </div>
    </Modal>
  );
};
