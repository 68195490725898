import React from 'react';
import StockMovementTable from './StockMovementTable';
import { STOCK_MARKET_TOP_LIST } from 'core/constant';

const MarketMovements = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      {STOCK_MARKET_TOP_LIST.map(i => (
        <StockMovementTable key={i.value} {...i} />
      ))}
    </div>
  );
};

export default MarketMovements;
