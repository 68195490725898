import React from 'react';

type MarketIconProps = {
  active?: boolean;
};

export const MarketIcon = ({ active }: MarketIconProps) => {
  return active ? (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2675_36520)">
        <path
          d="M8.5 5H11.5V14H8.5V17H6.5V14H3.5V5H6.5V2H8.5V5Z"
          fill="#0BA5EC"
        />
      </g>
      <path
        d="M18.5 10H21.5V19H18.5V22H16.5V19H13.5V10H16.5V7H18.5V10Z"
        fill="#0BA5EC"
      />
      <defs>
        <clipPath id="clip0_2675_36520">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.45 5V5.05H8.5H11.45V13.95H8.5H8.45V14V16.95H6.55V14V13.95H6.5H3.55V5.05H6.5H6.55V5V2.05H8.45V5ZM5.5 6.95H5.45V7V12V12.05H5.5H9.5H9.55V12V7V6.95H9.5H5.5ZM18.45 10V10.05H18.5H21.45V18.95H18.5H18.45V19V21.95H16.55V19V18.95H16.5H13.55V10.05H16.5H16.55V10V7.05H18.45V10ZM15.5 11.95H15.45V12V17V17.05H15.5H19.5H19.55V17V12V11.95H19.5H15.5Z"
        fill="#475467"
        stroke="#475467"
        strokeWidth="0.1"
      />
    </svg>
  );
};
