import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  title?: string;
  classNameCard?: string;
};

export const OnboardingCard = ({ children, title, classNameCard }: Props) => {
  return (
    <div className="px-[80px] pt-8 pb-[76px]">
      <div className="max-w-[960px] mx-auto">
        {!!title ? (
          <h1 className="text-xl text-gray-900 font-semibold leading-6 mb-6">
            {title}
          </h1>
        ) : null}
        <div
          className={`bg-black_blue border border-gray rounded-lg p-6 min-h-[440px] ${
            classNameCard ? classNameCard : ''
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
