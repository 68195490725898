import React, { ReactNode } from 'react';

type Props = {
  className?: string;
  type?: any;
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?:
    | 'primary'
    | 'secondary'
    | 'white'
    | 'yellow'
    | 'orange-warning'
    | 'green'
    | 'red'
    | 'blue';
};

export const Button = ({
  className,
  type,
  size = 'medium',
  disabled,
  children,
  variant = 'primary',
  onClick
}: Props) => {
  const variantStyle =
    variant === 'primary'
      ? 'bg-primary hover:bg-[#FFF] hover:text-primary'
      : variant === 'secondary'
      ? 'bg-blackBlue !text-primary'
      : variant === 'yellow'
      ? 'bg-[#F4D942] hover:bg-[#f2d73f]'
      : variant === 'orange-warning'
      ? 'bg-[#FF7337] hover:bg-[#FF7337]'
      : variant === 'green'
      ? 'bg-vividMalachite hover:bg-vividMalachite'
      : variant === 'red'
      ? 'bg-carminePink'
      : variant === 'blue'
      ? 'bg-floorPriceColor'
      : '!bg-black';

  const sizeStyle =
    size === 'small' // Not yet defined
      ? ''
      : size === 'large'
      ? 'px-[18px] py-[10px] text-base'
      : 'px-[18px] py-[10px] text-sm';
  return (
    <>
      <button
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={`
          flex items-center justify-center gap-2 font-semibold text-white transition rounded-[4px] max-h-10 
          disabled:cursor-not-allowed disabled:text-cadetGrey disabled:bg-gray border border-solid border-primary focus:none shadow-Shadow/xs
          ${variantStyle}
          ${sizeStyle}
          ${className}
        `}
      >
        {children}
      </button>
    </>
  );
};
