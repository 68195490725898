import React, { Fragment, useState, useEffect } from 'react';
import sbbs_logo from 'assets/images/sbbs_logo.png';
import {
  BellIcon,
  SearchIcon,
  HammerIcon,
  ArrowDown
} from 'assets/icons/index';
import { Button } from 'components/buttons/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RiAccountCircleFill, RiLogoutCircleFill } from 'react-icons/ri';
import { Menu, Transition } from '@headlessui/react';
import { useAccount } from 'core/contexts/AccountContext';
import { AccountService, ConfigService, FirestoreService } from 'core';
import { SelectIcon } from 'assets/icons';
import { getAccountShortDescription, getAccountName } from 'core/utils';
import useComponentVisible from 'core/hooks/UseComponentVisible';
import StockOptionSelect from '../StockOrder/StockOptionSelect';

const randomColor = () => {
  const colorArr = [
    '#FF7337',
    '#F4D942',
    '#0BA5EC',
    '#FF7337',
    '#F4D942',
    '#0BA5EC'
  ];
  const random = Math.floor(Math.random() * colorArr.length);
  return colorArr[random];
};

const NavBar = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [symbol, setSymbol] = useState('');
  const { account, accountInfo, updateAccount } = useAccount();
  const { userInfo, updateUserInfo } = useAccount();

  const getUserData = async () => {
    const res = await AccountService.getUserInfo();
    if (res?.data?.status === 'Success') {
      updateUserInfo(res?.data?.data);
    }
  };

  const initStock = async () => {
    let responseStocks = await FirestoreService.getListVNINDEX();
    let data: any = responseStocks?.docs.map((docSnapshot: any) =>
      docSnapshot.data()
    );
    localStorage.setItem('STOCKS', JSON.stringify(data));
  };

  useEffect(() => {
    if (!userInfo) {
      getUserData();
    }
    initStock();
  }, []);

  const getFistLetterName = (name: string) => {
    if (name) {
      const words = name.split(' ');
      const lastWord = words[words.length - 1];
      return lastWord?.charAt(0)?.toUpperCase();
    }
    return '';
  };

  return (
    <div className="flex justify-between items-center px-4 h-[70px] bg-black">
      <a href="/" title="SBBS">
        <img src={sbbs_logo} alt="SBBS Logo" className="max-h-[52px]" />
      </a>
      <div className="flex justify-center items-center gap-4">
        <BellIcon />
        <div ref={ref} className="relative">
          <div className="absolute top-[10px] left-4">
            <SearchIcon className="w-5 h-5" />
          </div>
          <input
            type="text"
            className="search-input w-[327px] h-10"
            placeholder="Tìm kiếm cổ phiếu"
            onFocus={() => {
              setSymbol('');
              setIsComponentVisible(true);
            }}
            onChange={e => {
              setSymbol(e.target.value);
            }}
          />
          {isComponentVisible && (
            <StockOptionSelect
              symbol={symbol}
              onSelectItem={v => {
                setIsComponentVisible(false);
                searchParams.append('stock', v);
                setSearchParams(searchParams);
              }}
            />
          )}
        </div>

        <Button
          className="text-primary font-semibold !bg-black"
          onClick={() => navigate('/stock-order')}
        >
          <HammerIcon active={true} />
          <span>Đặt lệnh</span>
        </Button>

        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button>
            <Button className="text-primary font-semibold min-w-[120px] !bg-black">
              <p>{getAccountName(account?.account_type_id)}</p>
              <ArrowDown />
            </Button>
          </Menu.Button>
          {accountInfo && accountInfo?.accounts.length > 0 ? (
            <Transition
              as={Fragment}
              enter="transition duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-1"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-1"
              leaveTo="opacity-0"
            >
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute right-0 top-[55px] w-[209px] bg-yankeesBlue px-4 py-2 rounded-md menu-profile">
                  {accountInfo?.accounts.map((item: any) => (
                    <div
                      className={`p-2 flex gap-2 relative text-gray rounded-md cursor-pointer ${
                        account?.account_type_id === item?.account_type_id
                          ? 'isSelectStyle'
                          : ''
                      }`}
                      key={item?.account_type_id}
                      onClick={() => updateAccount(item)}
                    >
                      <div className="min-w-[40px] h-10 rounded shadow-Shadow/xs flex justify-center items-center bg-gray command-code">
                        <p className="text-yankeesBlue text-sm font-semibold leading-4 text-center">
                          {item?.account_type_id}
                        </p>
                      </div>
                      <div className="flex flex-col gap-1 ">
                        <p className="text-sm font-semibold leading-5">
                          {getAccountName(item?.account_type_id)}
                        </p>
                        <p className="line-clamp-2 text-xs font-normal leading-[18px]">
                          {getAccountShortDescription(item?.account_type_id)}
                        </p>
                      </div>
                      {account?.account_type_id === item?.account_type_id ? (
                        <div className="absolute right-2 top-2">
                          <SelectIcon />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </Transition>
            </Transition>
          ) : null}
        </Menu>
        <Menu as="div" className="relative inline-block">
          <Menu.Button>
            {userInfo?.avatar ? (
              <img
                src={userInfo?.avatar}
                alt="Avatar"
                className="w-10 h-10 rounded-full"
              />
            ) : (
              <div
                className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer`}
                style={{ backgroundColor: randomColor() }}
              >
                <p className="text-[22.5px] font-bold tracking-[-0.138px] text-[#000]">
                  {getFistLetterName(userInfo?.full_name || '')}
                </p>
              </div>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute right-0 top-[55px] w-[209px] bg-yankeesBlue px-4 py-2 rounded-md menu-profile">
              <ul>
                <li
                  className="flex items-center gap-2 my-2 cursor-pointer"
                  onClick={() => {
                    navigate('/account');
                  }}
                >
                  <RiAccountCircleFill className="text-lg" />
                  <span className="text-sm flex-1">Thông tin tài khoản</span>
                </li>
                <li
                  className="flex items-center gap-2 my-2 cursor-pointer"
                  onClick={() => {
                    navigate('/logout');
                  }}
                >
                  <RiLogoutCircleFill />
                  <span className="text-sm flex-1">Đăng xuất</span>
                </li>
              </ul>
            </div>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default NavBar;
