import { MARKET, BIZ, ARTICLE } from '../constant/api.constant';
import axiosInstance, { axiosInstanceNews } from './restful.service';
import { STOCK_MARKET_TOP, STOCK_MARKET_PERIOD } from 'core/constant';
import { UpdateOrderParamsType } from 'core/types';

const getMarketStock = (
  type: STOCK_MARKET_TOP,
  period?: STOCK_MARKET_PERIOD,
  page_size?: number
) => {
  const params = {
    type,
    period,
    page_size
  };
  return axiosInstance.get(MARKET.STOCK, { params });
};

const getStockInfo = (symbol: string) => {
  return axiosInstance.get(BIZ.STOCK_INFO(symbol));
};

const getStockBuyingPower = (params: any) => {
  return axiosInstance.get(BIZ.STOCK_BUYING_POWER, { params });
};

const getOrderBook = (params: any) => {
  return axiosInstance.get(BIZ.ORDER_BOOK, { params });
};

const getStockHolding = (params: any) => {
  return axiosInstance.get(BIZ.STOCK_HOLDING, { params });
};

const buyStock = (params: any) => {
  return axiosInstance.post(BIZ.BUY_STOCK, params);
};

const sellStock = (params: any) => {
  return axiosInstance.post(BIZ.SELL_STOCK, params);
};

const updateOrder = (params: UpdateOrderParamsType) => {
  return axiosInstance.post(BIZ.UPDATE_STOCK, params);
};

const cancelOrder = (params: any) => {
  return axiosInstance.post(BIZ.CANCEL_ORDER, params);
};

const getCashOrder = (accountNo: string) => {
  const params = {
    accountNo: accountNo
  };
  return axiosInstance.get(BIZ.CASH_PORTFOLIO, { params });
};

const getCashTransactionHistory = (params: any) => {
  return axiosInstance.get(BIZ.CASH_TRANSACTION_HISTORY, { params });
};

const getProfileStock = (params: any) => {
  return axiosInstance.get(MARKET.PROFILE_STOCK, { params });
};

const getStockNews = (params: any) => {
  return axiosInstanceNews.get(ARTICLE.SEARCH, { params });
};

const getStockPortfolio = (params: any) => {
  return axiosInstance.get(BIZ.STOCK_PORTFOLIO, { params });
};

const getStockAnalysis = (params: any) => {
  return axiosInstance.get(MARKET.ANALYSIS, { params });
};

export const MarketStockService = {
  getMarketStock,
  getStockInfo,
  getStockBuyingPower,
  getOrderBook,
  getStockHolding,
  sellStock,
  buyStock,
  cancelOrder,
  updateOrder,
  getCashOrder,
  getCashTransactionHistory,
  getProfileStock,
  getStockNews,
  getStockPortfolio,
  getStockAnalysis
};
