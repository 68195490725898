import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components';
import SuccessIcon from 'assets/images/success_icon.png';
import Pattern from 'assets/images/pattern.png';
import { OnboardingService } from 'core/services';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

export const CreatePinCode = ({ closeModal }: { closeModal: () => void }) => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showRepin, setShowRepin] = useState(false);
  const [isSubmit, setIsSubmit] = useState<any>(false);

  const {
    register,
    handleSubmit,
    setError,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  });

  const onSubmit = async (data: any) => {
    setIsSubmit(true);
    if (data?.pin_code !== data?.repin_code) {
      setError('repin_code', {
        type: 'invalidRePin',
        message: 'Mã PIN nhập lại chưa khớp'
      });
      setIsSubmit(false);
      return;
    }
    const res = await OnboardingService.updatePin(data?.pin_code);
    if (res?.status?.toString() === 'Success') {
      setIsSuccess(true);
    } else {
      setError('repin_code', {
        type: 'invalidRePin',
        message: res?.error_message
          ? res?.error_message?.split('|')[1]
          : 'Có lỗi xảy ra, vui lòng thử lại sau.'
      });
    }
    setIsSubmit(false);
  };

  return (
    <>
      <div className="opacity-75 fixed inset-0 z-50 bg-black"></div>
      <div className="fixed z-50 top-0 bottom-0 right-0 left-0 flex justify-center items-center px-6">
        <div className="relative w-full max-w-[640px] min-h-[388px] bg-black_blue border border-gray p-6 rounded-[12px]">
          {isSuccess ? (
            <div className="flex flex-col min-h-[340px]">
              <img
                src={Pattern}
                width={216}
                height={216}
                className="absolute top-0 left-0"
                alt="Pattern"
              />
              <img
                src={SuccessIcon}
                width={48}
                height={48}
                alt="Success icon"
              />
              <div className="flex-1 pt-10">
                <div className="text-[20px] font-semibold text-gray-900 mb-1">
                  Gửi yêu cầu mở tài khoản SBBS thành công
                </div>
                <p className="text-gray-600 font-normal mb-2">
                  SBBS đã nhận được thông tin của Quý khách và đang kiểm tra
                  thông tin theo quy định của pháp luật.
                </p>
                <p className="text-gray-600 font-normal mb-2">
                  SBBS sẽ thông báo cho Quý khách khi tài khoản được phê duyệt
                  thành công trong thời gian sớm nhất (không quá 2 ngày làm
                  việc) qua email và điện thoại.
                </p>
                <p className="text-gray-600 font-normal">
                  Cám ơn Quý khách đã tin dùng dịch vụ của SBBS.
                </p>
              </div>
              <div className="flex gap-3 items-center justify-center">
                <Button
                  variant="secondary"
                  type="button"
                  className="flex-1 py-[9px]"
                  onClick={() => navigate('/')}
                >
                  Đóng
                </Button>
                <Button className="flex-1" onClick={() => navigate('/')}>
                  Xác nhận
                </Button>
              </div>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className="min-h-[340px] flex flex-col"
            >
              <h2 className="text-lg font-semibold mb-1">
                Tạo mã PIN giao dịch
              </h2>
              <p className="text-sm">
                Vui lòng nhập mã PIN bao gồm 6 số để có thể bắt đầu giao dịch
              </p>
              <div className="flex-1">
                <div className="form-input mt-5">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Nhập mã PIN mới
                  </label>
                  <div className="relative">
                    <input
                      className={`form-input__input ${
                        errors.pin_code && 'form-input__input_error'
                      }`}
                      maxLength={6}
                      type={showPin ? 'text' : 'password'}
                      autoComplete="off"
                      placeholder="Nhập mã PIN mới"
                      {...register('pin_code', {
                        onChange: () => {
                          trigger('pin_code');
                        },
                        required: true,
                        minLength: 6,
                        validate: valua => {
                          const re = /^[0-9\b]+$/;
                          return re.test(valua);
                        }
                      })}
                    />
                    <button
                      onClick={() => setShowPin(!showPin)}
                      type="button"
                      className="absolute right-4 top-1/2 transform -translate-y-1/2"
                    >
                      {showPin ? <RiEyeLine /> : <RiEyeOffLine />}
                    </button>
                  </div>
                  {errors.pin_code?.type === 'required' && (
                    <p className="text-sm font-normal leading-5 text-carminePink">
                      Vui lòng nhập mã PIN
                    </p>
                  )}
                  {errors.pin_code?.type === 'minLength' && (
                    <p className="text-sm font-normal leading-5 text-carminePink">
                      Không đúng định dạng, mã PIN bao gồm 6 chữ số
                    </p>
                  )}
                  {errors.pin_code?.type === 'validate' && (
                    <p className="text-sm font-normal leading-5 text-carminePink">
                      Không đúng định dạng, mã PIN chỉ bao gồm các số từ 0-9
                    </p>
                  )}
                </div>
                <div className="form-input mt-5">
                  <label htmlFor="" className="text-sm font-normal leading-5">
                    Nhập lại mã PIN
                  </label>
                  <div className="relative">
                    <input
                      className={`form-input__input ${
                        errors.pin_code && 'form-input__input_error'
                      }`}
                      maxLength={6}
                      type={showRepin ? 'text' : 'password'}
                      autoComplete="off"
                      placeholder="Nhập lại mã PIN mới"
                      {...register('repin_code', {
                        onChange: () => {
                          trigger('repin_code');
                        },
                        required: true,
                        validate: valua => {
                          const re = /^[0-9\b]+$/;
                          return re.test(valua);
                        }
                      })}
                    />
                    <button
                      onClick={() => setShowRepin(!showRepin)}
                      type="button"
                      className="absolute right-4 top-1/2 transform -translate-y-1/2"
                    >
                      {showRepin ? <RiEyeLine /> : <RiEyeOffLine />}
                    </button>
                  </div>
                  {errors.repin_code?.type === 'required' && (
                    <p className="form-errors">Vui lòng nhập mã PIN</p>
                  )}
                  {errors.repin_code?.type === 'validate' && (
                    <p className="form-errors">
                      Không đúng định dạng, mã PIN chỉ bao gồm các số từ 0-9
                    </p>
                  )}
                  {errors.repin_code?.type === 'invalidRePin' && (
                    <p className="form-errors">
                      {errors.repin_code?.message as String}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-3 items-center justify-center mt-4">
                <Button
                  variant="secondary"
                  type="button"
                  className="flex-1 py-[9px]"
                  onClick={() => closeModal()}
                  disabled={isSubmit}
                >
                  Đóng
                </Button>
                <Button className="flex-1" type="submit" disabled={isSubmit}>
                  Xác nhận
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};
