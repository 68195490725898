import React, { useEffect, useState } from 'react';
import SortTable from './SortTable';
import StockRow from './StockRow';
import { FirestoreService } from 'core';
import { useSortableData } from 'core/hooks';
import { SpinnerIcon } from './spinner/SpinnerIcon';

const _ = require('lodash');

const StockTable = ({
  data,
  showFull,
  isRefreshWhenSort,
  handleClickStock
}: {
  data: any[];
  showFull?: boolean;
  isRefreshWhenSort?: boolean;
  handleClickStock?: (stock: string) => void;
}) => {
  const [dataSorted, setDataSorted] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { requestSort, sortConfig, setSortConfig } = useSortableData();

  useEffect(() => {
    setSortConfig(undefined);
    setDataSorted(data);
  }, [data]);

  useEffect(() => {
    onSort();
  }, [sortConfig]);

  const onSort = async () => {
    if (!data) {
      return;
    }
    let dataNew = [...data];
    if (!sortConfig) {
      setDataSorted(dataNew);
      setLoading(false);
      return;
    }
    if (isRefreshWhenSort && sortConfig?.key !== 'symbol') {
      const symbols: string[] = data.map(i => i.symbol);

      const responseStocks = await FirestoreService.getStockSymbolTheoDoi(
        symbols
      );
      let dataStocks = responseStocks?.docs.map((docSnapshot: any) =>
        docSnapshot.data()
      );

      dataNew = dataStocks;
    }
    const sorted_data = _.orderBy(
      dataNew,
      [
        (o: any) => {
          return o[sortConfig.key] || '';
        }
      ],
      [sortConfig.direction]
    );
    setDataSorted(sorted_data);
    setLoading(false);
  };

  return (
    <div className="text-sm font-medium h-full relative pr-[2px] pt-[42px]">
      <div
        className={`absolute left-0 top-0 w-full flex bg-black_blue px-3 py-2 gap-2 ${
          showFull ? 'xl:px-8 xl:gap-[8%]' : 'xl:px-4 xl:gap-[4%]'
        }`}
      >
        <div className={`${showFull ? 'w-[14.5%]' : 'w-[20%]'}`}>
          <div
            className={`inline-flex items-center cursor-pointer`}
            onClick={() => requestSort('symbol')}
          >
            <span>Mã CK</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'symbol' ? sortConfig?.direction : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right ${showFull ? 'w-[13%]' : 'w-[22%]'}`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('last_price')}
          >
            <span>Giá</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'last_price'
                  ? sortConfig?.direction
                  : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right ${showFull ? 'w-[14.5%]' : 'w-[18%]'}`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('ratio_change')}
          >
            <span>%</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'ratio_change'
                  ? sortConfig?.direction
                  : undefined
              }
            />
          </div>
        </div>
        <div className={`text-right ${showFull ? 'w-[16%]' : 'flex-1'}`}>
          <div
            className={`inline-flex items-center justify-end cursor-pointer`}
            onClick={() => requestSort('total_vol')}
          >
            <span>KL</span>
            <SortTable
              sortDirection={
                sortConfig?.key === 'total_vol'
                  ? sortConfig?.direction
                  : undefined
              }
            />
          </div>
        </div>
        {showFull ? <div className="flex-1"></div> : null}
      </div>
      {loading ? (
        <div className="flex justify-center items-center">
          <SpinnerIcon className="scale-50" />
        </div>
      ) : (
        <div
          className={`max-h-full overflow-y-auto px-2 scrollbar-custom ${
            showFull ? 'xl:px-8' : 'xl:px-4'
          }`}
        >
          {dataSorted?.map(stock => {
            return (
              <StockRow
                showFull={showFull}
                key={stock?.symbol}
                stock={stock}
                handleClickStock={handleClickStock}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StockTable;
