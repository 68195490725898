import { LoadingBarProcess, MasterLayout } from 'components';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from './Routes';
import StockOrderPage from 'pages/stock-order';

export const StockOrderRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <RequireAuth>
                <StockOrderPage />
              </RequireAuth>
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};
