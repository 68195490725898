import React from 'react';

export const ArrowRight = ({ className }: { className?: string }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M10.9767 10.5001L6.85165 6.37511L8.02999 5.19678L13.3333 10.5001L8.02999 15.8034L6.85165 14.6251L10.9767 10.5001Z"
          fill="white"
        />
      </svg>
    </>
  );
};
