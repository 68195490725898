import { WALLET } from '../constant/api.constant';
import axiosInstance from './restful.service';

export type WalletCashBalReponseData = {
    allow_online_withdraw: string;
    max_withdrawal_amt: number;
    withdrawal_bal: number;
    min_withdrawal_amt: number;
    used_daily_transfer_amt: number;
    max_daily_transfer_amt: number;
}

const getCashBal = (accountId: string, currencyId?: string) => {
    let params: any = {
        AccountId: accountId
    };
    if (currencyId) {
        params = {
            ...params,
            CurrencyId: currencyId
        }
    }
    return axiosInstance.get(WALLET.GET_CASH_BAL, { params });
};

const withdrawal = (params: any) => {
    return axiosInstance.post(WALLET.WITHDRAWAL, params);
}

export const WalletService = {
    getCashBal,
    withdrawal
}