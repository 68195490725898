import React, { ReactNode } from 'react';

export const Card = ({
  children,
  className,
  value
}: {
  children: ReactNode;
  className?: string;
  value?: number;
}) => {
  return (
    <div
      className={`${className} bg-smokyBlack rounded-lg p-4 border-[2px] border-solid ${
        value
          ? value > 0
            ? 'border-darkGreen'
            : 'border-carminePink'
          : 'border-mustardYellow'
      }`}
    >
      {children}
    </div>
  );
};
