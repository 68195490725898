export const HOST_URL = process.env.REACT_APP_API_ENDPOINT;
export const HOST_URL_NEWS = process.env.REACT_APP_API_ENDPOINT_NEWS;
export const VPBS_HOST_URL = process.env.REACT_APP_VPBS_API_ENDPOINT;
export const AUTH_HOST_URL = process.env.REACT_APP_AUTH_API_ENDPOINT;
export const STATICS_PATH = process.env.REACT_APP_STATICS_PATH;
export const IDENTIFY_HOST_URL = process.env.REACT_APP_authority;

export const MARKET = {
  STOCK: '/market/statistics/stock-market',
  PROFILE_STOCK: '/market/statistics/profile',
  ANALYSIS: '/market/statistics/analysis'
};

export const ARTICLE = {
  SEARCH: '/sbbs-news/search'
};

export const BIZ = {
  ACCOUNT: '/biz/account/info',
  STOCK_BUYING_POWER: '/biz/trade/buying-power',
  STOCK_HOLDING: `/biz/trade/stock-holding`,
  STOCK_INFO: (symbol: string) => `/biz/trade/stock-info?stockSymbol=${symbol}`,
  watchlist: () => `/biz/watchlists`,
  ORDER_BOOK: `/biz/trade/order-book`,
  TOTAL_ASSET: 'biz/trade/total-asset',
  BUY_STOCK: 'biz/trade/buy-stock',
  SELL_STOCK: 'biz/trade/sell-stock',
  UPDATE_STOCK: 'biz/trade/update-order',
  CANCEL_ORDER: 'biz/trade/cancel-order',
  CASH_PORTFOLIO: 'biz/trade/cash-portfolio',
  CASH_TRANSACTION_HISTORY: 'biz/trade/cash-transaction-histories',
  STOCK_PORTFOLIO: 'biz/trade/stock-portfolio',
  CASH_DISTRIBUTION: 'biz/trade/cash-distribution',
  BANK_LIST: '/biz/bank/list',
  BANK_INFO: (bankKey: string, accountNumber: string) =>
    `biz/bank/${bankKey}/account/${accountNumber}/info`,
  ADD_BANK: '/biz/bank/user',
  PIN: '/biz/account/pin',
  SIGN_CONTRACT: '/biz/account/sign-contract',
  SYSTEM_CONFIG: '/biz/system-config',
  CASH_STATEMENT: 'biz/trade/cash-statement',
  AVAILABLE_CIA: `biz/trade/avaiable-cia`,
  CREATE_CIA: `biz/trade/create-cia`,
  CIA_INFO: `biz/trade/cia-info`
};

export const TRADE = {
  TOTAL_ASSET: 'biz/trade/total-asset',
  TOTAL_ASSET_DISTRIBUTION: 'biz/trade/total-asset-distribution',
  STOCK_PORTFOLIO: 'biz/trade/stock-portfolio',
  CASH_PORTFOLIO: 'biz/trade/cash-portfolio',
  CASH_HISTORIES: 'biz/trade/cash-transaction-histories',
  CASH_TRANSFER_INSIDE: 'biz/trade/cash-transfer-inside'
};

export const ACCOUNT_PIN = {
  UPDATE: '/biz/account/pin',
  VERIFY: '/biz/account/pin/verify'
};

export const USER = {
  UPDATE_PASSWORD: '/user/password',
  CHECK_PASSWORD: '/user/password/check',
  USER_INFO: '/user/info',
  CLOUD_STORAGE: '/cloud-storage/signed-url',
  UPDATE_AVATAR: '/user/profile/avatar',
  CHECK_EMAIL_EXIST: '/update-email/check-email-existed',
  SEND_OTP: '/update-email/otp/send',
  UPDATE_EMAIL: '/update-email'
};

export const SYSTEMCONFIG = {
  GET: '/biz/system-config'
};

export const WALLET = {
  WITHDRAWAL: `/biz/wallet/withdrawal`,
  GET_CASH_BAL: `/biz/wallet/balance/cash`
};

export const EKYC = {
  GET_ID_INFO: `/user/profile/ocr`,
  CHECK_FACE: `/user/profile/liveness`,
  UPLOAD_IMAGE: '/cloud-storage/signed-url',
  UPDATE_PROFILE: '/user/profile/ocr/info',
  USER_INFO: '/user/info'
};

export const BANK = {
  GET_BANK_USER: `/biz/bank/user`,
  ADD_BANK_USER: `/biz/bank/user`,
  DELETE_BANK_USER: `/biz/bank/user`,
  LIST: `/biz/bank/list`,
  GET_BANK_ACCOUNT_INFO: (bankKey: string, accountNumber: string) =>
    `/biz/bank/${bankKey}/account/${accountNumber}/info`
};
