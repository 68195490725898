import { BIZ, SYSTEMCONFIG, EKYC } from '../constant/api.constant';
import axiosInstance, { axiosInstanceIdentity } from './restful.service';

const fetchInfoId = async (params: any) => {
  return axiosInstanceIdentity.post(EKYC.GET_ID_INFO, params).then(res => {
    const data = res?.data;
    return data;
  });
};

const fetchUser = async () => {
  return axiosInstanceIdentity.get(EKYC.USER_INFO).then(res => {
    const data = res?.data;
    return data;
  });
};

const checkFace = async (params: any) => {
  return axiosInstanceIdentity.post(EKYC.CHECK_FACE, params).then(res => {
    const data = res?.data;
    return data;
  });
};

const fetchUrlUpload = async (
  type: 'CccdBack' | 'CccdFront' | 'FaceLiveness' | 'SignContract' | 'Other'
) => {
  const params = { FileType: type };
  return axiosInstanceIdentity.get(EKYC.UPLOAD_IMAGE, { params }).then(res => {
    const data = res?.data;
    return data?.data;
  });
};

const updateProfile = async (params: {
  transaction_id: string;
  address: string;
  gender: 'F' | 'M';
  email: string;
  tax_number: string;
  expire_date: any;
}) => {
  return axiosInstanceIdentity.put(EKYC.UPDATE_PROFILE, params).then(res => {
    const data = res;
    return data?.data;
  });
};

const fetchListBank = async (): Promise<any> => {
  return axiosInstance.get(BIZ.BANK_LIST).then(res => {
    const data = res?.data;
    const fornatData = data?.data?.map((i: any) => ({
      label: i?.name,
      value: i?.key
    }));
    return fornatData;
  });
};

const fetchBankInfo = async (
  bankKey: string,
  accountNumber: string
): Promise<any> => {
  return axiosInstance.get(BIZ.BANK_INFO(bankKey, accountNumber)).then(res => {
    const data = res?.data;
    return data?.data;
  });
};

const addBankUser = async (params: any): Promise<any> => {
  return axiosInstance.post(BIZ.ADD_BANK, params).then(res => {
    const data = res;
    return data;
  });
};

const updatePin = async (pin: string): Promise<any> => {
  return axiosInstance.post(BIZ.PIN, { pin }).then(res => {
    const data = res?.data;
    return data;
  });
};

const signContract = async (url: string): Promise<any> => {
  return axiosInstance
    .post(BIZ.SIGN_CONTRACT, {
      sign_contract_url: url
    })
    .then(res => {
      const data = res;
      return data?.data;
    });
};

const fetchSystemConfig = async (): Promise<any> => {
  return axiosInstance.get(BIZ.SYSTEM_CONFIG).then(res => {
    const data = res;
    return data?.data;
  });
};

export const OnboardingService = {
  fetchBankInfo,
  checkFace,
  fetchUser,
  fetchInfoId,
  signContract,
  updatePin,
  fetchListBank,
  addBankUser,
  updateProfile,
  fetchSystemConfig,
  fetchUrlUpload
};
